<div class="encabezado">
  <h5 class="titulo1">{{ "settings.title" | translate }}</h5>
  <!--
    <span class="titulo1"> Configuracion</span> -->
  <div class="menu-horizontal">
    <button *ngFor="let boton of botones; let i = index" [class.selected]="i === botonSeleccionado"
      (click)="seleccionarBoton(i)">
      {{ boton | translate }}
    </button>
  </div>
  <div class="row">
    <div class="col-6">
      <a routerLink="/settings/automation">
        <div class="setting-card" (click)="changeOpt()">
          <div class="iconleft">
            <img src="assets/images/setting-notif.png" class="img-fluid" alt="" height="22" />
          </div>
          <div class="text">
            <h3>{{ "automation.cardtitle" | translate }}</h3>
            <p>
              {{ "automation.cardtext" | translate }}
            </p>
          </div>
          <div class="iconright">
            <i class="icon-chevron-right"></i>
          </div>
        </div>
      </a>
    </div>

    <div class="col-6">
      <a routerLink="/settings/profile">
        <div class="setting-card" (click)="changeOpt()">
          <div class="iconleft">
            <img src="assets/images/setting.png" class="img-fluid" alt="" height="22" />
          </div>
          <div class="text">
            <h3>{{ "settings.profile.cardtitle" | translate }}</h3>
            <p>
              {{ "settings.profile.cardtext" | translate }}
            </p>
          </div>
          <div class="iconright">
            <i class="icon-chevron-right"></i>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <a routerLink="/settings/users">
        <div class="setting-card" (click)="changeOpt()">
          <div class="iconleft">
            <img src="assets/images/setting-users.png" class="img-fluid" alt="" height="22" />
          </div>
          <div class="text">
            <h3>{{ "settings.user.cardtitle" | translate }}</h3>
            <p>
              {{ "settings.user.cardtext" | translate }}
            </p>
          </div>
          <div class="iconright">
            <i class="icon-chevron-right"></i>
          </div>
        </div>
      </a>
    </div>


  </div>

  <div *ngIf="opt" class="container-fluid">
    <router-outlet></router-outlet>
  </div>
</div>
