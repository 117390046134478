<form [formGroup]="vehicleForm">

  <div class="form-section">
    <div class="row">
      <div class="col-4">
        <div class="mb-3">
          <label for="vehicle_status" class="form-label">{{
            "forms.inputs.status" | translate
            }}</label>
          <select id="vehicle_status" class="form-control" formControlName="vehicle_status">
            <option value="" disabled selected>
              {{ "forms.inputs.select_option" | translate }}
            </option>
            <option value="new" selected>
              {{ "forms.inputs.status_select.new" | translate }}
            </option>
            <option value="used">
              {{ "forms.inputs.status_select.used" | translate }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="mb-3">
          <label for="vehicle_units" class="form-label">{{
            "forms.inputs.vehicle_units" | translate
            }}</label>
          <input type="text" class="form-control" id="vehicle_units" required formControlName="vehicle_units"
            placeholder="{{ 'forms.inputs.vehicle_units' | translate }}" />
        </div>
      </div>
      <div class="col-4 div-download" *ngIf="orderData?.vehicle_units > 1">
        <div class="mb-3">
          <label class="form-label subtext"><em>{{
              "forms.labels.duplicate_orders_text1"| translate
              }}<a  class="a_pulsable" (click)="duplicate()">{{
                "forms.labels.duplicate_orders_text2"| translate: { unidades: orderData?.vehicle_units }
                }}</a>
              {{
              "forms.labels.duplicate_orders_text3"| translate
              }}</em></label>
        </div>
      </div>
    </div>
    <div class="row linea"></div>
    <div class="row">
      <div class="col-4">
        <div class="mb-3">
          <label for="brand" class="form-label">{{ "forms.inputs.brand" | translate }}
            <span class="required">*</span></label>
          <select id="vehicle_brand_id" class="form-control" formControlName="vehicle_brand_id"
            (change)="onBrandChange($event)">
            <option value="" disabled selected>
              {{ "forms.inputs.brand" | translate }}
            </option>
            <option *ngFor="let brand of brands" [value]="brand.reference">
              {{ brand.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-4">
        <div class="mb-3">
          <label for="model" class="form-label">{{ "forms.inputs.model" | translate }}
            <span class="required">*</span></label>
          <select id="vehicle_model_id" class="form-control" formControlName="vehicle_model_id">
            <option value="" disabled selected>
              {{ "forms.inputs.model" | translate }}
            </option>
            <option *ngFor="let model of models" [value]="model.reference">
              {{ model.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="mb-3">
          <label for="year" class="form-label">{{
            "forms.inputs.year" | translate
            }}</label>
          <input type="text" class="form-control" id="vehicle_model_year" required formControlName="vehicle_model_year"
            placeholder="{{
              'forms.inputs.year_model' | translate
            }}" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-8">
        <div class="mb-3">
          <label for="vehicle" class="form-label">{{
            "forms.inputs.vehicle" | translate
            }}</label>
          <input type="text" class="form-control" id="vehicle" required formControlName="vehicle"
            placeholder="{{ 'forms.inputs.vehicle' | translate }}" />
        </div>
      </div>
      <div class="col-4">
        <div class="mb-3">
          <label for="colour" class="form-label">{{
            "forms.inputs.colour" | translate
            }}</label>
          <input type="text" class="form-control" id="colour" required formControlName="colour"
            placeholder="{{ 'forms.inputs.colour' | translate }}" />
        </div>
      </div>
    </div>
    <div class="row linea"></div>
    <div class="row">
      <div class="col-6">
        <div class="mb-3">
          <label for="vin" class="form-label">{{
            "forms.inputs.vin" | translate
            }}</label>
          <div class="input-group div-copy">

            <input type="text" class="form-control" id="vin" required formControlName="vin"
              placeholder="{{ 'forms.inputs.vin' | translate }}" #inputFieldvin />

            <div class="input-group-append">
              <button class="btn btn-outline-secondary copy" type="button"
                (click)="copyToClipboard(inputFieldvin.value,$event)">
                <i class="icon-copy"></i>
              </button>
            </div>
          </div>
        </div>

      </div>
      <div class="col-6">
        <div class="mb-3">
          <label for="license_plate" class="form-label">{{
            "forms.inputs.license_plate" | translate
            }}</label>
          <div class="input-group div-copy">

            <input type="text" class="form-control" id="license_plate" required formControlName="license_plate"
              placeholder="{{ 'forms.inputs.license_plate' | translate }}" #inputFieldplate />

            <div class="input-group-append">
              <button class="btn btn-outline-secondary copy" type="button"
                (click)="copyToClipboard(inputFieldplate.value,$event)">
                <i class="icon-copy"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="mb-3">
          <label for="vehicle_mileage" class="form-label">{{
            "forms.inputs.vehicle_mileage" | translate
            }}</label>

          <div class="currencydiv">
            <input type="text" class="form-control" id="vehicle_mileage" required formControlName="vehicle_mileage"
              placeholder="{{ 'forms.inputs.vehicle_mileage' | translate }}" />
            <span> kms</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label for="vehicle_registration_date" class="form-label">{{
            "forms.inputs.vehicle_registration_date" | translate
            }}</label>

          <mat-form-field appearance="fill">
            <input matInput [matDatepicker]="vehicle_registration_date" formControlName="vehicle_registration_date"
              placeholder="{{
                'forms.inputs.vehicle_registration_date' | translate
              }}" />
            <mat-datepicker-toggle matSuffix [for]="vehicle_registration_date"></mat-datepicker-toggle>
            <mat-datepicker #vehicle_registration_date></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row linea"></div>
    <div class="row d-flex align-items-end">
      <div class="col-6">
        <div class="mb-3">
          <label for="dealership" class="form-label">{{
            "forms.inputs.dealership" | translate
            }}</label>
          <select id="dealership_id" class="form-control" formControlName="dealership_id">
            <option value="" disabled selected>
              {{ "forms.inputs.dealership" | translate }}
            </option>
            <option *ngFor="let dealership of dealershipData" [value]="dealership.reference">
              {{ dealership.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label for="vehicle_billing_date" class="form-label">{{ "forms.inputs.vehicle_billing_date"
            | translate
            }}</label>

          <mat-form-field appearance="fill">
            <input matInput [matDatepicker]="vehicle_billing_date" formControlName="vehicle_billing_date" placeholder="{{
                'forms.inputs.vehicle_billing_date' | translate
              }}" />
            <mat-datepicker-toggle matSuffix [for]="vehicle_billing_date"></mat-datepicker-toggle>
            <mat-datepicker #vehicle_billing_date></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">

      <div class="col-6">
        <div class="mb-3">
          <label for="vehicle_retail_price" class="form-label">{{
            "forms.inputs.vehicle_retail_price" | translate
            }}</label>

          <div class="currencydiv">
            <input type="text" class="form-control" id="vehicle_retail_price" required
              formControlName="vehicle_retail_price"
              placeholder="{{ 'forms.inputs.vehicle_retail_price_pvp' | translate }}" />
            <span> EUR</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
