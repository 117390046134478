<div class="d-flex">
  <div class="document-buttons">
    <div id="btn-general" class="document-button general selected">
      <button type="submit" class="btn-documents">
        <i class="icon-user"></i>
        {{ "forms.buttons.new" | translate }}
      </button>
    </div>
  </div>

  <div class="card w-100 form-right">

    <div class="back">
      <div class="div-title">
        <h2 class="">
          {{ "forms.names.addUser" | translate }}
        </h2>
      </div>
      <i class="icon-cross2" (click)="emitBackButtonClick()"></i>
    </div>

    <div class="card-body">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-section mb-3">

          <div class="row mb-3">
            <div class="col-12">
              <div class="mb-3">
                <label for="roles" class="form-label">{{ "forms.inputs.roles" | translate }}
                  <span class="required">*</span>
                </label>
                <div class="position-relative">
                  <select id="roles" class="form-control" formControlName="roles">
                    <option value="" disabled selected>{{ "forms.inputs.roles" | translate }}</option>
                    <option value="Admin">{{ "forms.inputs.Admin" | translate }}</option>
                    <option value="Agent">{{ "forms.inputs.Agent" | translate }}</option>
                    <option value="Supervisor">{{ "forms.inputs.Supervisor" | translate }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="row linea"></div>


          <div class="row mb-3">
            <div class="col-6" *ngIf="form.get('type')?.value !== 'organization'">
              <div class="mb-3">
                <label for="vendor" class="form-label">{{ "forms.inputs.name" | translate }}
                  <span class="required">*</span>
                </label>
                <input id="vendor" class="form-control" formControlName="name"
                  placeholder="  {{ 'forms.inputs.name' | translate }}" />
              </div>
            </div>
            <div class="col-6" *ngIf="form.get('type')?.value !== 'organization'">
              <div class="mb-3">
                <label for="supplier" class="form-label">{{ "forms.inputs.lastname" | translate }}</label>
                <input id="lastname" class="form-control" formControlName="lastname"
                  placeholder=' {{ "forms.inputs.lastname" | translate }}' />
              </div>
            </div>
          </div>

          <div class="row linea"></div>

          <div class="row mb-3">
            <div class="col-12">
              <div class="mb-3">
                <label for="supplier" class="form-label">{{ "forms.inputs.email" | translate }}
                  <span class="required">*</span></label>
                <input id="email" class="form-control" formControlName="email"
                  placeholder=' {{ "forms.inputs.email" | translate }}' />
              </div>
              <div *ngIf="isFieldInvalid('email')">
                <small *ngIf="getFormError('email', 'required')" class="error_validation">El correo es
                  obligatorio.</small>
                <small *ngIf="getFormError('email', 'email')" class="error_validation">El formato del correo es
                  inválido.</small>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-4">
              <div class="mb-3">
                <label for="phone_prefix" class="form-label">{{ "forms.inputs.phone_prefix" | translate }}
                </label>
                <app-select-country-prefix (changePrefix)="changePrefix($event)"
                  [selectedPrefix]="form.get('phonePrefix')?.value"
                  [selectionMode]="'prefix'"></app-select-country-prefix>
              </div>
            </div>

            <div class="col-8">
              <div class="mb-3">
                <label for="phone" class="form-label">{{ "forms.inputs.phone" | translate }}
                </label>
                <input id="phone" class="form-control phone-number" formControlName="phoneNumber" type="tel"
                  placeholder="{{ 'forms.inputs.phone' | translate }}" (input)="onInput($event)" />
              </div>
              <div *ngIf="form.hasError('invalidPhoneNumber') && form.get('phoneNumber')?.touched">
                <small class="error_validation">Si el prefijo es +34, el número debe tener 9 dígitos.</small>
              </div>

              <div *ngIf="isFieldInvalid('phoneNumber')">
                <small class="error_validation">El teléfono debe tener 9 dígitos, o si empieza con +34, debe tener 9
                  dígitos adicionales.</small>
              </div>
            </div>
          </div>
          <div class="row linea"></div>
          <!-- <div *ngIf="edit" class="row mb-3">
            <label for="" class="form-label">{{
              "forms.inputs.refresh_password" | translate
              }}</label>
            <div class="col-6">
              <div class="button-create-container">
                <button type="submit" class="btn btn-primary btn-primary-form create-button">
                  {{ "buttons.refresh_password" | translate }}
                </button>
              </div>
            </div>
          </div> -->
          <!-- <div class="row linea"></div> -->

          <!-- <div *ngIf="edit" class="row mb-3">
            <label for="" class="form-label">{{
              "forms.inputs.current_password_text" | translate
              }}</label>
            <div class="col-6">
              <div class="mb-3">
                <label for="current_password" class="form-label">{{
                  "forms.inputs.current_password" | translate
                  }}</label>
                <input id="current_password" class="form-control" type="current_password" formControlName="current_password" placeholder="{{
                                          'forms.inputs.current_password' | translate
                                        }}" />
              </div>
            </div>

          </div>

          <div class="row mb-3">
            <div class="col-6">
              <div class="mb-3">
                <label for="password" class="form-label">{{
                  "forms.inputs.password" | translate
                  }}</label>
                <input id="password" class="form-control" type="password" formControlName="password" placeholder="{{
                                          'forms.inputs.password' | translate
                                        }}" />
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="password_confirmation" class="form-label">{{
                  "forms.inputs.password_confirmation" | translate
                  }}</label>
                <input id="password_confirmation" class="form-control" type="password" formControlName="password_confirmation" placeholder="{{
                                                          'forms.inputs.password_confirmation' | translate
                                                        }}" />
              </div>
            </div>
          </div> -->
          <!-- <div class="row linea"></div>-->

          <div *ngIf="edit" class="button-create-container">
            <button type="submit" class="btn btn-primary btn-primary-form create-button">
              {{ "buttons.update_user" | translate }}
            </button>
          </div>

          <div *ngIf="!edit" class="button-create-container">
            <button type="submit" class="btn btn-primary btn-primary-form create-button">
              {{ "buttons.create_user" | translate }}
            </button>
          </div>
        </div>
        <!-- </form> -->
      </form>
    </div>

  </div>

</div>
