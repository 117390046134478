import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../core/services/alert/alert.service';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { ReportsService } from '../../../core/services/reports/reports.service';
import { FilterComponent } from '../filter/filter.component';
import { AccountsService } from '../../../core/services/accounts/accounts.service';
import { BrandService } from '../../../core/services/brand/brand.service';

import { Chart, registerables } from 'chart.js';
import { OrdersService } from '../../../core/services/orders/orders.service';
import { OrderTableComponent } from '../order-table/order-table.component';

Chart.register(...registerables);
type SearchParamsEst = {
  [key: string]: string;
};

interface AccountData {
  reference: string;
  name: string;
  type: string;
  tax_id: string;
  billing_address: string;
  billing_city: string;
  billing_state: string;
  root_account: any;
  status: boolean;
}
interface ApiResponse {
  data: AccountData[];
}
@Component({
  selector: 'app-reports-view',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    RouterLink,
    ReactiveFormsModule,
    FormsModule,
    FilterComponent,
    OrderTableComponent
  ],
  templateUrl: './reports-view.component.html',
  styleUrl: './reports-view.component.css',
})
export class ReportsViewComponent {

  showForm = false;
  type: string = '';
  chart: Chart | undefined;
  dataSource = new MatTableDataSource<any>();
  accountType: string | null = null;
  orderData: any[] = [];
  ladata: any = [];
  searchParamsClickBar: SearchParamsEst = {};
  orderDataBar: any[] = [];
  showTable = false;
  typeOrders = "";
  sortColumn = 'stage_updated_at';
  sortDirection = 'asc';

  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
    private localStorageService: LocalStorageService,
    private reportsService: ReportsService,
    private accountService: AccountsService,
    private brandService: BrandService,
    private translateService: TranslateService
  ) {
    this.accountType = this.localStorageService.getItem('account_type');
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.type = params.get('type') || 'contracts';
      this.fetchAllData();
    });
  }

  private fetchAllData() {
    Promise.all([
      this.fetchData2(),
      this.fetchData3(),
    ]).then(() => {
      this.cargaladata.flag = true;
      this.ladata = this.cargaladata;
      setTimeout(() => {
        this.alertService.close();
      }, 500);
    });
  }

  toggleForm() {
    this.showForm = !this.showForm;
  }

  pageNumber: number = 0;
  pageSize: number = 0;
  searchParams: any = {};
  totalOrders: number = 0;
  requireActionTotal: number = 0;
  notRequireActionTotal: number = 0;
  cargaladata: any = [];

  brokerData: AccountData[] = [];
  vendorData: AccountData[] = [];
  supplierData: AccountData[] = [];
  brands: any[] = [];

  fetchOrderStages(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.searchParams['type'] = this.type;
      this.reportsService.getData(this.searchParams).subscribe({
        next: (data) => {
          this.dataSource.data = data.data;
          this.totalOrders = data.data.orders_total;
          this.requireActionTotal = data.data.require_action_total;
          this.notRequireActionTotal = data.data.not_require_action_total;

          const stages = data.data.orders_by_stage;
          this.orderStageData = Object.keys(stages).map((stage) => ({
            stage,
            requiresAction: stages[stage].require_action
              ? stages[stage].cant
              : 0,
            noAction: !stages[stage].require_action ? stages[stage].cant : 0,
          }));
          this.orderData = this.orderStageData;
          this.setupChart();
          resolve();
        },
        error: (error) => {
          this.alertService.error(error.error);
          reject(error);
        },
      });
    });
  }

  orderStageData: {
    stage: string;
    requiresAction: number;
    noAction: number;
  }[] = [];

  fetchData2(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.accountService.getData().subscribe({
        next: (response: ApiResponse) => {
          if (!response || !response.data) {
            resolve();
            return;
          }
          const data: AccountData[] = response.data;
          this.cargaladata.brokerData = data.filter(
            (item) => item.type === 'broker'
          );
          this.cargaladata.supplierData = data.filter(
            (item) => item.type === 'supplier'
          );
          this.cargaladata.vendorData = data.filter(
            (item) => item.type === 'vendor'
          );
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData3(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.brandService.getData().subscribe({
        next: (data) => {
          this.cargaladata.brands = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  filterChanged(searchParams: any): void {
    this.searchParams = searchParams;
    this.pageNumber = 1;
    this.alertService.loading('swal_messages.please_wait');

    this.fetchOrderStages()
      .then(() => {
        this.alertService.close();
      })
      .catch((error) => {
        console.error('Error loading clients:', error);
        this.alertService.error('Error loading clients');
      });
  }


  //GRAFICO
  private async translateLabels(labels: string[]): Promise<string[]> {
    const translations = await Promise.all(
      labels.map((label) =>
        this.translateService.get(`reports.labels.${label}`).toPromise()
      )
    );
    return translations;
  }

  async setupChart(): Promise<void> {
    const originalLabels = this.orderData.map((item) => item.stage);
    const labels = await this.translateLabels(originalLabels);
    let visibleLabels = labels;
    let visibleLabelIndexMap = new Map(
      visibleLabels.map((label, index) => [label, originalLabels[index]])
    );

    const requiresActionData = this.orderData.map((item) => item.requiresAction);
    const noActionData = this.orderData.map((item) => item.noAction);

    const hasData =
      requiresActionData.some((value) => value > 0) ||
      noActionData.some((value) => value > 0);

    if (!hasData) {
      requiresActionData.fill(0);
      noActionData.fill(0);
    }

    const ctx = (document.getElementById('myChart') as HTMLCanvasElement).getContext('2d');

    const onClick = (event: any, elements: any) => {
      if (elements.length > 0) {
        const chartElement = elements[0];
        const index = chartElement.index;
        const clickedLabel = visibleLabels[index];
        const originalLabel = visibleLabelIndexMap.get(clickedLabel);
        if (!this.searchParams.end_date) {
          const today = new Date();
          const day = String(today.getDate()).padStart(2, '0');
          const month = String(today.getMonth() + 1).padStart(2, '0');
          const year = today.getFullYear();
          this.searchParams.end_date = `${year}-${month}-${day}`;
        }

        if (!this.searchParams.start_date) {
          const today = new Date();
          const day = String(today.getDate()).padStart(2, '0');
          const month = String(today.getMonth() + 1).padStart(2, '0');
          const year = today.getFullYear();
          this.searchParams.start_date = `${year}-${month}-${day}`;
        }

        let aux: SearchParamsEst = {};
        aux[`${this.searchParams.stage_for_date}_date[between][]`] = `['${this.searchParams.start_date}','${this.searchParams.end_date}']`;
        aux[`stage[in]`] = `${originalLabel}`;
        this.searchParamsClickBar = aux;

      }
    };

    const onHover = (event: any, elements: any) => {
      event.native.target.style.cursor = elements.length > 0 ? 'pointer' : 'default';
    };

    if (ctx) {
      if (this.chart) {
        this.chart.data.labels = visibleLabels;
        this.chart.data.datasets[0].data = requiresActionData;
        this.chart.data.datasets[1].data = noActionData;

        visibleLabelIndexMap = new Map(
          visibleLabels.map((label, index) => [label, originalLabels[index]])
        );
        this.chart.options.onClick = onClick;
        this.chart.options.onHover = onHover;
        this.chart.update();
      } else {
        this.chart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: visibleLabels,
            datasets: [
              {
                label: this.translateService.instant('reports.requires_action'),
                data: requiresActionData,
                backgroundColor: 'rgb(242, 116, 116)',
              },
              {
                label: this.translateService.instant('reports.no_action_required'),
                data: noActionData,
                backgroundColor: 'rgb(196, 253, 243)',
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: 'top',
              },
            },
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
                beginAtZero: true,
              },
            },
            onClick: onClick,
            onHover: onHover,
          },
        });
      }
    }
  }




}
