import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-reports',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, TranslateModule],
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.css',
})
export class ReportsComponent {
  botones = ['settings.buttons.general'];
  botonSeleccionado = 0;
  opt = false;

  seleccionarBoton(index: number) {
    this.botonSeleccionado = index;
    this.opt = false;
  }

  changeOpt() {
    this.opt = !this.opt;
  }
}
