import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, empty, map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private apiUrl = environment.apiURL + 'orders'; // URL del API
  private apiUrlNotes = environment.apiURL + 'order-notes'; // URL del API
  private apiUrlLogs = environment.apiURL + 'order-logs'; // URL del API
  private apiUrlAcc = environment.apiURL + 'accounts';
  private apiUrlDelivery = environment.apiURL + 'delivery-data';

  constructor(private http: HttpClient) {}

  getData(
    type: string = 'contracts',
    pageNumber: number = 1,
    pageSize: number = 10,
    params: any = {},
    sortColumn: string = 'stage_updated_at',
    sortDirection: string = 'asc'
  ): Observable<any> {
    var url = `${this.apiUrl}/stages/${type}`;
    if (type == '') {
      url = `${this.apiUrl}`;
    }

    return this.http.get(url, {
      params: {
        page: pageNumber.toString(),
        pageSize: pageSize.toString(),
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        ...params,
      },
    });
  }

  deleteOrder(reference: string): Observable<any> {
    return this.http.delete(this.apiUrl + '/' + reference + '/delete');
  }
  getDataToInvoices(params: any = {}): Observable<any> {
    var url = `${this.apiUrl}/to-invoices`;
    return this.http.get(url, {
      params: {
        ...params,
      },
    });
  }

  getFilterData(
    type: string = 'contracts',
    pageNumber: number = 1,
    pageSize: number = 10,
    params: any = {},
    sortColumn: string = 'stage_updated_at',
    sortDirection: string = 'asc'
  ): Observable<any> {
    var url = `${this.apiUrl}/stages/${type}`;
    if (type == '') {
      url = `${this.apiUrl}`;
    }

    return this.http.get(url, {
      params: {
        page: pageNumber.toString(),
        pageSize: pageSize.toString(),
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        ...params,
      },
    });
  }
  getDataByReference(reference: string = ''): Observable<any> {
    const url = `${this.apiUrl}/${reference}`;
    return this.http.get(url);
  }

  getStages(): Observable<any> {
    return this.http.get(`${this.apiUrl}/stages`);
  }
  getStagesMap(item: any): Observable<any> {
    item = item == '' ? 'all' : item;
    return this.http.get(`${this.apiUrl}/stages/map/`+ item);
  }
  getTimeline(item: any): Observable<any> {
    return this.http.get(this.apiUrlLogs + '/timeline/' + item);
  }
  // Método para añadir datos a la API
  addData(item: any): Observable<any> {
    return this.http.post(this.apiUrl + '/new', item);
  }

  addNotes(item: any): Observable<any> {
    return this.http.post(this.apiUrlNotes + '/new', item);
  }

  getNotes(item: any): Observable<any> {
    return this.http.get(this.apiUrlNotes + '/order/' + item);
  }

  getNotesLast(
    pageNumber: number = 1,
    pageSize: number = 10,
    params: any = {}
  ): Observable<any> {
    var url = `${this.apiUrlNotes}/last`;

    return this.http.get(url, {
      params: {
        page: pageNumber.toString(),
        pageSize: pageSize.toString(),
        ...params,
      },
    });
  }

  getDeliveryData(): Observable<any> {
    return this.http.get(this.apiUrlDelivery);
  }
  // Método para añadir datos a la API
  searchOrder(reference: string): Observable<any> {
    return this.http.get(this.apiUrl + '/' + reference);
  }

  // Método para añadir datos a la API
  updateData(reference: string, body: any): Observable<any> {
    return this.http.put(this.apiUrl + '/' + reference + '/update', body);
  }

  updateStatus(reference: string, body: any): Observable<any> {
    return this.http.put(this.apiUrl + '/' + reference + '/update-stage', body);
  }

  duplicateOrder(reference: string): Observable<any> {
    return this.http.get(this.apiUrl + '/' + reference + '/duplicate');
  }

  //filtered orders
  getOrders(params: any = {}): Observable<any> {
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, params[key]);
      }
    }
    return this.http.get<any>(`${this.apiUrl}`, {
      params: httpParams,
    });
  }

  searchOrders(search: string): Observable<any> {
    return this.http.get<any[]>(`${this.apiUrl}?search=${search}`);
  }

  searchOrdersByFilter(filters: { [key: string]: string }): Observable<any> {
    let url = `${this.apiUrl}`;
    const params = [];

    for (const key in filters) {
      if (filters.hasOwnProperty(key) && filters[key]) {
        params.push(`${key}=${filters[key]}`);
      }
    }

    if (params.length > 0) {
      url += '?' + params.join('&');
    }

    return this.http.get<any>(url);
  }

  getAccounts(): Observable<any> {
    return this.http.get<any>(`${this.apiUrlAcc}`);
  }

  filterOrdersByBrand(brand: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}?vehicle_brand=${brand}`);
  }

  toggleActionRequired(reference: string): Observable<any> {
    const url = `${this.apiUrlNotes}/${reference}/action-required/toogle`;
    return this.http.put(url, {});
  }

  updateActionRequiredBatch(
    noteIds: number[],
    actionRequired: boolean
  ): Observable<void> {
    return this.http.put<void>(`${this.apiUrlNotes}/action-required/toogle`, {
      references: noteIds,
      action_required: actionRequired,
    });
  }

  getActionRequiredCount(): Observable<any> {
    return this.http.get<any>(`${this.apiUrlNotes}/last/action-required`);
  }

  private actionRequiredCountSource = new BehaviorSubject<number>(0);
  actionRequiredCount$ = this.actionRequiredCountSource.asObservable();

  updateActionRequiredCount(count: number): void {
    this.actionRequiredCountSource.next(count);
  }

  initializeActionRequiredAutoUpdate(): void {
    setInterval(() => {
      this.getActionRequiredCount().subscribe({
        next: (response) => {
          const count = response.data?.action_requireds || 0;
          this.updateActionRequiredCount(count);
        },
        error: (err) => {
          console.error(
            'Error al actualizar automáticamente el número de notas:',
            err
          );
        },
      });
    }, 180000);
  }
}
