import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { MainComponent } from './pages/main/main.component';
import { AuthGuard } from './core/guard/auth/auth.guard';
import { TitleResolver } from './core/resolver/metatitles/metatitles.resolver';
import { NotFoundComponent } from './pages/component/not-found/not-found.component';
import { HelpComponent } from './pages/component/header/help/help.component';
import { RecoverPasswordComponent } from './pages/login/recover-password/recover-password.component';
import { ResetPasswordComponent } from './pages/login/reset-password/reset-password.component';
import { IconComponent } from './pages/icon/icon.component';
import { invoicesRoutes } from './core/routes/invoices-routing.module';
import { usersRoutes } from './core/routes/users-routing.module';
import { settingRoutes } from './core/routes/settings-routing.module';
import { reportsRoutes } from './core/routes/reports-routing.module';
import { clientsRoutes } from './core/routes/clients-routing.module';
import { ordersRoutes } from './core/routes/orders-routing.module';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'icon',
        component: IconComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.orders' },
      },
      ...ordersRoutes,
      ...clientsRoutes,
      ...reportsRoutes,
      ...settingRoutes,
      ...invoicesRoutes,
      ...usersRoutes,
      {
        path: 'help',
        component: HelpComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
  },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
