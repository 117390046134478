import { Routes } from '@angular/router';
import { TitleResolver } from '../resolver/metatitles/metatitles.resolver';
import { ClientsComponent } from '../../pages/clients/clients.component';

export const clientsRoutes: Routes = [
  {
    path: 'clients',
    component: ClientsComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.clients' },
  },
  {
    path: 'clients/:reference',
    component: ClientsComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.clients' },
  },
  {
    path: 'clients/add',
    component: ClientsComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.clients' },
  },
];
