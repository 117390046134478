<div class="encabezado">
  <div>
    <div>
      <div>
        <h5 class="titulo1">{{ "pages.titles." + title | translate }}</h5>
      </div>
    </div>
  </div>
  <div class="">
    <div class="">
      <div class="row">
        <div class="col-8">
          <!-- <app-filter [ladata]="ladata" (filterChanged)="filterChanged($event)"></app-filter> -->
        </div>
        <div class="col-4">
          <div class="btn-izq">
            <button class="btn btn-hover" type="button" (click)="toggleForm()">
              {{ "buttons.add+" | translate }} <i class="icon-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="tabla">
      <table mat-table class="full-width-table" matSort aria-label="Brands" multiTemplateDataRows
        [dataSource]="dataSource">

        <!-- serial Column -->
        <ng-container matColumnDef="reference">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla">
            {{ "tables.columns.reference" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row?.reference">
              {{ row?.reference }}
            </span>
          </td>
        </ng-container>

        <!-- name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla">
            {{ "tables.columns.name" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row?.name">
              {{ row?.name }}
            </span>
          </td>
        </ng-container>

        <!-- lastname Column -->
        <ng-container matColumnDef="lastname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla">
            {{ "tables.columns.lastname" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row?.lastname">
              {{ row?.lastname }}
            </span>

          </td>
        </ng-container>

        <!-- roles Column -->
        <ng-container matColumnDef="roles">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla">
            {{ "tables.columns.roles" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">

            <div class="column-list">
              <span *ngIf="row?.roles">
                {{ "forms.inputs."+ row?.roles | translate }}
              </span>
              <span *ngIf="! row?.roles">
                {{ "forms.inputs.norole" | translate }}
              </span>
            </div>
          </td>
        </ng-container>

        <!-- email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla">
            {{ "tables.columns.email" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row?.email">
              {{ row?.email }}
            </span>
          </td>
        </ng-container>


        <!-- phone Column -->
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla">
            {{ "tables.columns.phone" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row?.phone">
              {{ row?.phone }}
            </span>
          </td>
        </ng-container>


        <!-- img Column -->
        <ng-container matColumnDef="img">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla">
            {{ "tables.columns.img" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">

            <img *ngIf="row?.img" class="img-list-users" src="{{row?.img}}" alt="" height="22" />
          </td>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla">
            {{ "tables.columns.status" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row?.status" class="badge text-badge-list"
              [ngClass]="getBadgeColor('active' ,row.require_action,row.priority)">{{
              "badge.active" | translate
              }}</span>
               <span *ngIf="!row?.status" class="badge text-badge-list"
               [ngClass]="getBadgeColor('inactive' ,row.require_action,row.priority)">{{
               "badge.inactive" | translate
               }}</span>
          </td>
        </ng-container>

        <!-- actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla">
            {{ "tables.columns.actions" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <i class="icon-pencil text-success icon-actions" (click)="getUser(row.reference)" aria-hidden="true"></i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="toggleRow(row)"
          [ngClass]="{'tr-selected': expandedRow?.reference === row.reference}"></tr>
        <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->

      </table>

      <mat-paginator [length]="paginator.length ? paginator.length : 25" [pageSize]="paginator.pageSize"
        [pageSizeOptions]="paginator.pageSizeOptions" (page)="onPageChange($event)">
      </mat-paginator>
    </div>


  </div>

  <div class="formulario-derecha-container" [ngClass]="{ 'mostrar-formulario': showForm }">
    <app-form-add-users *ngIf="showForm" [userData]="userData" (backButtonClick)="cerrarFormulario()"
      (addUser)="refrescar()"></app-form-add-users>
  </div>


  <div class="overlay" *ngIf="showForm" (click)="cerrarFormulario()"></div>
</div>
