import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertService } from '../../../../core/services/alert/alert.service';
import { VehiclesService } from '../../../../core/services/vehicles/vehicles.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-helper-modal',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './helper-modal.component.html',
  styleUrl: './helper-modal.component.css'
})
export class HelperModalComponent {
  @Input() step?: any;
  @Input() orderData?: any;
  @Input() brand_id_modal?: any;
  @Input() brand?: any;
  @Output() optionSelected = new EventEmitter<string>();
  @Output() closeHelper = new EventEmitter<any>();


  isOpen = false;

  brandmodel = false;

  model_reference: any;
  optionsModels: any;
  optionsOffert: any;
  optionsContracts: any;
  selectedOption: any = {};

  breadcrumb_brand: any;
  breadcrumb_model: any;
  breadcrumb_offert: any;
  breadcrumb_contract: any;

  constructor(
    private alertService: AlertService,
    private vehiclesService: VehiclesService
  ) { }


  ngOnInit(): void {
    this.alertService.loading();
    this.vehiclesService.topModelBrand(this.orderData?.supplier.reference, this.brand_id_modal).subscribe({
      next: (data) => {
        this.optionsModels = data.data;
        this.breadcrumb_brand = this.optionsModels[0]?.brand?.name;
        this.step = 1;
        this.alertService.close();
        if (data.data.length > 0) { this.openModal() } else { this.closeModal() };
      },
      error: (err) => {
        console.error('Error al obtener las ordenes del cliente:', err);
        this.alertService.close();
      },
    });

  }

  getBadgeColorVehicle(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      new: 'text-new',
      used: 'text-used',
    };
    return statusColorMap[status] || 'text-new';
  }

  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
    this.closeHelper.emit();
  }

  selectOption1(option: any) {
    this.model_reference = option.reference;
    this.breadcrumb_model  = option.name;
    this.alertService.loading();
    this.vehiclesService.topModel(this.orderData?.supplier.reference, this.model_reference).subscribe({
      next: (data) => {
        this.optionsOffert = data.data;
        this.step = 2;
        this.alertService.close();
      },
      error: (err) => {
        console.error('Error al obtener las ordenes del cliente:', err);
        this.alertService.close();
      },
    });

  }

  selectOption2(option: any) {
    this.breadcrumb_offert  = option.vehicle;
    this.selectedOption.vehicle_model_id = this.model_reference;
    this.selectedOption.vehicle = option.vehicle;
    this.selectedOption.vehicle_model_year = option.vehicle_model_year;
    this.selectedOption.colour = option.colour;
    this.selectedOption.vehicle_status = option.vehicle_status;
    this.selectedOption.vehicle_units = 1;
    this.optionsContracts = option.details;
    this.step = 3;
  }

  selectOption3(option: any) {
    this.breadcrumb_contract  = option.duration;
    this.selectedOption.duration = option.duration;
    this.selectedOption.type = option.type;
    this.selectedOption.include_insurance = option.include_insurance;
    this.selectedOption.include_maintenance = option.include_maintenance;
    this.selectedOption.include_replacement_vehicle = option.include_replacement_vehicle;
    this.selectedOption.include_tires = option.include_tires;
    this.selectedOption.monthly_fee_base = option.monthly_fee_base;
    this.selectedOption.monthly_fee_tax = option.monthly_fee_tax;
    this.selectedOption.monthly_fee_total = option.monthly_fee_total;
    this.selectedOption.total_kms = option.total_kms;
    this.selectedOption.yearly_kms = option.yearly_kms;
    this.optionSelected.emit(this.selectedOption);
    this.closeModal();
  }

  update_breadcrumb(step: number) {
    this.step = step;
    if (step == 1) {
      this.breadcrumb_model = null;
      this.breadcrumb_offert = null;
      this.breadcrumb_contract = null;
    } else if (step == 2) {
      this.breadcrumb_offert = null;
      this.breadcrumb_contract = null;
    } else if (step == 3) {
      this.breadcrumb_contract = null;
    }
  }
}

