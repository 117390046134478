<div class="vertical-menu"  [ngClass]="{ 'menu-chico': collapsed}">
  <div class="navbar-brand-box" *ngIf="!collapsed"  >
    <a class="logo logo-light">
      <span class="logo-lg">
        <img src="assets/images/logo-abbr-clear.png" alt="" height="22" />
      </span>
      <i class="icon-arrow-left" (click)="toggleNavigation(true)" ></i>
    </a>
  </div>

  <div class="navbar-brand-box menu-collapsed " *ngIf="collapsed"  >
    <a class="logo ">
      <i class="icon-menu" (click)="toggleNavigation(false)" ></i>
    </a>
  </div>


  <div>
    <div data-simplebar class="sidebar-menu-scroll">
      <div class="name-bussines" *ngIf="!collapsed">
        <div class="d-flex align-items-center" >
          <div class="circle">{{ name.charAt(0) }}</div>
          <div class="d-flex flex-column" [ngClass]="{ 'display1': collapsed}">
            <span>{{ name }}</span>
            <span class="type">{{ account_name }}</span>
          </div>
        </div>
        <a *ngIf="role == 'Admin'" class="has-submenu" (click)="toggleSubmenuConf($event)" [ngClass]="{ 'icon-grande': collapsed}"
          (mouseenter)="cancelCloseTimeoutConf()" (mouseleave)="startCloseTimeoutConf()"><i class="icon-cog"></i>
        </a>

      </div>
      <div class="name-bussines-collapsed " *ngIf="collapsed">

        <a *ngIf="role == 'Admin'" class="has-submenu" (click)="toggleSubmenuConf($event)" [ngClass]="{ 'icon-grande': collapsed}"
          (mouseenter)="cancelCloseTimeoutConf()" (mouseleave)="startCloseTimeoutConf()"><i class="icon-cog"></i>
        </a>

      </div>
      <div id="sidebar-menu">
        <ul class="metismenu list-unstyled" id="side-menu">
          <div *ngIf="type == 'broker'" id="sidebar-menu">
            <li class="has-submenu" (click)="toggleSubmenu($event)" (mouseenter)="cancelCloseTimeout()"
              (mouseleave)="startCloseTimeout()">
              <a class="resaltar">
                <div class="d-flex">
                  <i class="icon-plus"></i>
                  <span class="menu-item" data-key="t-submenu" [ngClass]="{ 'display1': collapsed}">
                    {{ "menu.create" | translate }}
                  </span>
                </div>
                <i class="icon-chevron-right" [ngClass]="{ 'display1': collapsed}"></i>
              </a>
            </li>
          </div>
          <li class="menu-title" data-key="t-menu">
            {{ "menu.menu" | translate }}
          </li>
          <li *ngFor="let item of menuItems" class="menu-item-has-children">
            <div class="" *ngIf="!item.role || (item.role && item.role.includes(role))">
              <a class="d-flex justify-content-between" [routerLink]="item.route || null"
              (click)="item.children ? (item.key === 't-orders' ? toggleSubmenuOrders() : toggleSubmenuInvoices()) : null">
              <div>
                <i [class]="item.icon" [ngClass]="{ 'icon-grande': collapsed}" ></i>
                <span class="menu-item" [attr.data-key]="item.key" [ngClass]="{ 'display1': collapsed}" >{{
                  item.label | translate
                  }}</span>
              </div>
              <i *ngIf="item.children" class=""  [ngClass]="{ 'display1': collapsed,
                  'icon-chevron-up': item.key === 't-orders' ? submenuOrders : submenuInvoices,
                  'icon-chevron-down': !(item.key === 't-orders' ? submenuOrders : submenuInvoices )
                }"></i>
            </a>
            <ul *ngIf="item.children && (item.key === 't-orders' ? submenuOrders : submenuInvoices)" class="submenu">
              <li *ngFor="let child of item.children">
                <a [routerLink]="child.route">
                  <div>
                    <i [class]="child.icon"></i>
                    <span class="menu-item" [attr.data-key]="child.key">{{
                      child.label | translate
                      }}</span>
                  </div>
                </a>
              </li>
            </ul>
            </div>

          </li>
        </ul>
      </div>
    </div>
   </div>

  <div class="nav-second-level grid-2x2" [ngClass]="{collapse: submenuOpen ,  collapse2: collapsed}"  (mouseenter)="cancelCloseTimeout()"
    (mouseleave)="startCloseTimeout()">
    <div class="row" (mouseenter)="cancelCloseTimeout()" (mouseleave)="startCloseTimeout()">
      <div class="col-6 submenuitem">
        <a class="subitem-menu" [href]="'/orders/contracts/add'">
          <i class="icon-clipboard-pencil"></i>
          <a>
            <span class="menu-item" data-key="t-calendar">{{
              "menu.order" | translate
              }}</span>
          </a>
        </a>
      </div>
      <div class="col-6 submenuitem">
        <a class="subitem-menu" [href]="'/clients/add'">

          <i class="icon-user-plus"></i>
          <a>
            <span class="menu-item" data-key="t-calendar">{{
              "menu.client" | translate
              }}</span>
          </a>
        </a>
      </div>
      <div class="col-6 submenuitem">
        <a class="subitem-menu" [href]="'/invoices/add'">
          <i class="icon-clipboard-check"></i>
          <a>
            <span class="menu-item" data-key="t-calendar">{{
              "menu.invoices" | translate
              }}</span>
          </a>
        </a>
      </div>
    </div>
  </div>

  <div class="nav-settings grid-2x2"   [ngClass]="{collapse: submenuOpenConf ,  collapse2: collapsed}"  (mouseenter)="cancelCloseTimeoutConf()"
    (mouseleave)="startCloseTimeoutConf()">
    <div class="row" (mouseenter)="cancelCloseTimeoutConf()" (mouseleave)="startCloseTimeoutConf()">
      <div class="col-12 submenuitem">
        <div class="subitem-menu" routerLink="/settings">
          <i class="icon-cog"></i>
          <a>
            <span class="menu-item" data-key="t-settings">{{
              "menu.settings" | translate
              }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>

</div>
