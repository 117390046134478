import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UsersService } from '../../core/services/users/users.service';
import { LocalStorageService } from '../../core/services/localStorage/local-storage.service';
import { AlertService } from '../../core/services/alert/alert.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, TranslateModule, RouterLink],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css',
})
export class ProfileComponent {
  showForm = false;
  form: FormGroup;
  formPersonalData: FormGroup;
  formImage: FormGroup;
  userData: any;

  activeForm = 'general';

  imageUrl: string | ArrayBuffer | null = null;
  showPassword = { current: false, new: false, repeat: false };

  selectedFile: File | null = null;

  constructor(
    private fb: FormBuilder,
    private usersService: UsersService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService
  ) {
    this.formPersonalData = this.fb.group({
      name: [''],
      email: [''],
      roles: [''],
    });
    this.form = this.fb.group({
      currentPassword: [''],
      newPassword: [''],
      repeatPassword: [''],
    });

    this.formImage = this.fb.group({
      image: [null],
    });
  }

  ngOnInit(): void {
    this.fetchUserData();
  }

  loadUserData(reference: string) {
    this.usersService.getUserByReference().subscribe((user) => {
      this.formPersonalData.patchValue({
        name: user.name,
        email: user.email,
        roles: user.roles,
      });
    });
  }

  fetchUserData(): void {
    this.usersService.getUserByReference().subscribe(
      (response) => {
        this.userData = response;
        console.log('User Data:', this.userData);

        if (this.userData.data.img) {
          this.imageUrl = this.userData.data.img;
          console.log(this.imageUrl);
        } else {
          this.imageUrl = '../../../assets/images/user-empty.png';
        }

        // Llenar otros datos del usuario en el formulario
        this.formData(this.userData);
        this.isUserDataLoaded = true;
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  isUserDataLoaded = false;

  updateUser(): void {
    if (this.form.valid && this.isUserDataLoaded) {
      const userReference = this.localStorageService.getItem('user_reference');
      const formData = this.form.value;

      const payload = {
        name: formData.name,
        current_password: formData.currentPassword,
        password: formData.newPassword,
        password_confirmation: formData.repeatPassword,
      };

      if (userReference) {
        this.usersService.updateUser(userReference, payload).subscribe(
          (response) => {
            this.alertService.successOk('swal_messages.users.user_updated');
          },
          (error) => {
            if (
              error.error?.current_password &&
              Array.isArray(error.error.current_password)
            ) {
              const errorMessage =
                'swal_messages.users.current_password_incorrect';
              this.alertService.error(errorMessage);
            } else if (error.error && error.error.password) {
              const passwordError =
                'swal_messages.users.password_confirmation_incorrect';
              this.alertService.error(passwordError);
            } else {
              this.alertService.error('swal_messages.users.user_updated_error');
            }
          }
        );
      } else {
        console.error('Referencia de usuario no encontrada en local storage');
      }
    }
  }

  toggleForm() {
    this.showForm = !this.showForm;
  }

  cerrarFormulario() {
    this.showForm = false;
  }

  formData(userData: any): void {
    const user = userData.data;

    this.formPersonalData.patchValue({
      name: user.name,
      email: user.email,
      roles: user.roles,
    });
  }

  togglePasswordVisibility(field: 'current' | 'new' | 'repeat'): void {
    this.showPassword[field] = !this.showPassword[field];
  }

  hasValue(field: string): boolean {
    return !!this.form.get(field)?.value;
  }

  updatePersonalData(): void {
    if (this.formPersonalData.valid && this.isUserDataLoaded) {
      const userReference = this.localStorageService.getItem('user_reference');
      const formData = this.formPersonalData.value;

      const payload = {
        name: formData.name,
      };
      if (userReference) {
        this.usersService.updateUserData(userReference, payload).subscribe(
          (response) => {
            this.alertService.success('profile.updated-succesfully');
          },
          (error) => {
            this.alertService.error('profile.error-updating');
          }
        );
      } else {
        console.error('Referencia de usuario no encontrada en local storage');
        this.alertService.error('profile.error-reference');
      }
    }
  }

  triggerFileInput() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  submitImage() {
    if (this.selectedFile) {
      const formData = new FormData();
      const userReference = this.localStorageService.getItem('user_reference');

      formData.append('img', this.selectedFile);

      this.alertService.loading('profile.sending-image');

      if (userReference) {
        this.usersService
          .uploadProfileImage(userReference, formData)
          .subscribe({
            next: (response) => {
              this.alertService.success('profile.image-updated');
              this.usersService.updateUserImage(response.data.img);
            },
            error: (error) => {
              this.alertService.error('profile.image-error');
            },
            complete: () => {
              this.alertService.close();
            },
          });
      }
    } else {
      this.alertService.error('profile.image-required');
    }
  }
}
