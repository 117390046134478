import { Routes } from '@angular/router';
import { TitleResolver } from '../resolver/metatitles/metatitles.resolver';
import { OrdersComponent } from '../../pages/orders/orders.component';

export const ordersRoutes: Routes = [
    {
         path: '',
         redirectTo: 'orders/contracts',
         pathMatch: 'full',
       },
       {
         path: 'orders',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.orders' },
       },

       {
         path: 'orders/all',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.all' },
       },
       {
         path: 'orders/cancelled',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.cancelled' },
       },
       {
         path: 'orders/delivered',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.delivered' },
       },
       {
         path: 'orders/drafts',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.delivered' },
       },
       {
         path: 'orders/offers',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.offers' },
       },
       {
         path: 'orders/offers/:reference',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.offers' },
       },
       {
         path: 'orders/sqls',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.sqls' },
       },
       {
         path: 'orders/sqls/:reference',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.sqls' },
       },
       {
         path: 'orders/drafts/:reference',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.delivered' },
       },
       {
         path: 'orders/all/:reference',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.all' },
       },
       {
         path: 'orders/denied',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.denied' },
       },
       {
         path: 'orders/denied/:reference',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.denied' },
       },

       {
         path: 'orders/rejected',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.rejected' },
       },
       {
         path: 'orders/rejected/:reference',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.rejected' },
       },
       {
         path: 'orders/contracts',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.contracts' },
       },
       {
         path: 'orders/after-sales',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.after-sales' },
       },
       {
         path: 'orders/cancellation-requested',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.cancellation_requested' },
       },
       {
         path: 'orders/cancellation-requested/:reference',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.cancellation_requested' },
       },
       {
         path: 'orders/cancelled/:reference',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.cancelled' },
       },
       {
         path: 'orders/delivered/:reference',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.delivered' },
       },
       {
         path: 'orders/contracts/add',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.orders' },
       },
       {
         path: 'orders/contracts/:reference',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.orders' },
       },
       {
         path: 'orders/contracts/:reference/:tab',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.orders' },
       },
       {
         path: 'orders/after-sales/:reference',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.orders' },
       },
       {
         path: 'orders/:reference',
         component: OrdersComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.orders' },
       },
];
