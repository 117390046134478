<div class="contenedor">
  <!-- <label class="form-label">{{ "forms.inputs.timeline" | translate }} </label>
 -->
  <div class="timeline-container">
    <div class="timeline-line"></div>
    <div *ngFor="let itemBig of timelineItems">
      <div class="timeline-item d-flex ">

        <div class="div-details d-flex flex-column">
          <div *ngFor="let item of itemBig.events">

            <div class="div-aux d-flex align-items-center">
              <div class="div-time">
                <span class="time">{{ item.time }}</span>
              </div>
              <div class="div-icon">
                <i [ngClass]="getIcon(item.message)"></i>
              </div>
              <div class="details">
                <ul class="no-bullets">
                  <li *ngFor="let change of item.values">
                  <span *ngIf="item.message == 'updates'"> <span [innerHTML]="change | updatePipe"></span></span>
                  <span *ngIf="item.message == 'duplicated'"> <span  style="white-space: nowrap;" [innerHTML]="change | updatePipe5"></span></span>
                  <span *ngIf="item.message == 'documents'"><span [innerHTML]="change | updatePipe2"></span></span>
                  <span *ngIf="item.message == 'stages'"> <span [innerHTML]="change | updatePipe3"></span></span>
                  <span *ngIf="item.message == 'notes'"><span [innerHTML]="change | updatePipe4"></span></span>
                  <span *ngIf="item.message == 'notes_risk'"><span [innerHTML]="change | updatePipe4"></span></span>
                  <span *ngIf="item.message == 'creation'">{{"timeline."+item.message | translate }}</span>
                </li>
                </ul>
                <span class="pipe-second"><i class="icon-user"></i> {{item.created_by}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="div-date">
        <span class="date">{{ itemBig.date | date : "mediumDate" }}</span>
      </div>
    </div>
  </div>
</div>

