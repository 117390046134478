import { Routes } from '@angular/router';
import { TitleResolver } from '../resolver/metatitles/metatitles.resolver';
import { UsersComponent } from '../../pages/users/users.component';

export const usersRoutes: Routes = [
  {
    path: 'settings/users',
    component: UsersComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.users' },
  },
  {
    path: 'settings/users/:reference',
    component: UsersComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.users' },
  },
  {
    path: 'settings/users/add',
    component: UsersComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.drafts' },
  },
  {
    path: 'settings/users/edit/:reference',
    component: UsersComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.drafts' },
  }
];
