<div class="prefix_select">
  <select class="form-control" [(ngModel)]="selectedCountryName" (ngModelChange)="onCountryChange($event)">
    <option *ngFor="let country of europeanCountries" [value]="country.name">
      {{ country.name }} <span *ngIf="showPhonePrefix">({{ country.phonePrefix }})</span>
    </option>
  </select>

  <img *ngIf="selectedCountry" [src]="selectedCountry.flag" alt="{{ selectedCountry.name }} flag" width="25">
</div>
<!--
<div class="prefix_select">
  <select class="form-control" (change)="onCountryChange($event)">
    <option *ngFor="let country of europeanCountries" [value]="country.name"
      [selected]="country.phonePrefix === selectedPrefix">
      {{ country.name }} <span *ngIf="showPhonePrefix">({{ country.phonePrefix }})</span>
    </option>
  </select>
  <img *ngIf="selectedCountry" [src]="selectedCountry.flag" alt="{{ selectedCountry.name }} flag" width="25">
</div> -->
