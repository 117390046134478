import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
} from '@angular/material/core';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { AlertService } from '../../../core/services/alert/alert.service';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { Observable, Subject, debounceTime, filter } from 'rxjs';
import { SelectCountryPrefixComponent } from '../../component/select-country-prefix/select-country-prefix.component';
import { CustomDateAdapter } from './custom-adapter';
import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { FormEditClientComponent } from '../form-edit-client/form-edit-client.component';

export function phoneNumberValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const phonePrefix = formGroup.get('phonePrefix')?.value;
    const phoneNumber = formGroup.get('phoneNumber')?.value;

    if (
      phonePrefix === '+34' &&
      (!phoneNumber || !/^[0-9]{9}$/.test(phoneNumber))
    ) {
      return { invalidPhoneNumber: true }; // Devuelve un error si no tiene 9 dígitos
    }

    return null; // Retorna nulo si todo está bien
  };
}
export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
interface FileItem {
  file: File;
  selectOption: string;
  key: string;
}
@Component({
  selector: 'app-form-add-client',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    NgSelectModule,
    SelectCountryPrefixComponent,
    FormEditClientComponent,
  ],

  templateUrl: './form-add-client.component.html',
  styleUrl: './form-add-client.component.css',
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
})
export class FormAddClientComponent {
  @Output() backButtonClick = new EventEmitter<boolean>();
  @Output() backButtonClick2 = new EventEmitter<boolean>();
  @Output() addClient = new EventEmitter<boolean>();

  form: FormGroup;

  clients: Array<{
    name: string;
    reference: string;
    tax_id: string;
    lastname: string;
  }> = [];
  filteredClients: Array<{ name: string; reference: string; tax_id: string }> =
    [];

  search$ = new Subject<string>();

  minDate: Date = new Date(1900, 0, 1);
  maxDate: Date = new Date();
  maxDateExpiration: Date = new Date(2040, 12, 12);

  step: number = 1;

  uploadFile = true;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('clientSelector') clientSelector: any;
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private clientService: ClientsService
  ) {
    this.form = this.fb.group(
      {
        status: [true],
        type: ['', Validators.required],
        tax_id: ['', Validators.required],
        tax_id_temp: [null],
        assignee_id: [null],
        name: ['', Validators.required],
        lastname: [''],
        email: ['', [Validators.required, Validators.email]],
        phone: [''],
        billing_address: ['', Validators.required],
        billing_postal_code: [
          '',
          [Validators.required, Validators.pattern('^[0-9]{4,5}$')],
        ],
        billing_city: ['', Validators.required],
        billing_state: ['', Validators.required],
        phonePrefix: ['+34'],
        birth_date: [null],
        birthplace_country: ['spain'],
        nationality: [''],
        document_expiration_date: [null],
        phoneNumber: ['', Validators.required],
      },
      { validators: phoneNumberValidator() }
    );

    this.form.get('type')?.valueChanges.subscribe(() => {
      this.form.updateValueAndValidity();
    });
  }

  ngOnInit(): void {
    this.loadIndividualClients();

    this.search$.pipe(debounceTime(200)).subscribe((term) => {
      this.fetchClient(term);
    });

    this.form.get('type')?.valueChanges.subscribe((typeValue) => {
      if (typeValue === 'organization') {
        this.form.get('lastname')?.reset();
        const assigneeIdControl = this.form.get('assignee_id');
        assigneeIdControl?.setValidators([
          Validators.required,
          this.nonEmptyValidator(),
        ]);
      }
    });

    this.form
      .get('tax_id')
      ?.valueChanges.pipe(
        debounceTime(200),
        filter((taxId) => !!taxId)
      )
      .subscribe((taxId) => {
        this.checkTaxId(taxId);
      });
  }

  taxIdExists = false;
  existingClient: any;

  showAlertFilter = false;
  showCountFilter = 0;
  showAlertFilterAll = false;
  existingClientReference: string = '';

  private nonEmptyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim(); // Remueve espacios
      return value ? null : { nonEmpty: true };
    };
  }

  birthDateValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const type = form.get('type')?.value;
      const birthDate = form.get('birth_date')?.value;

      if (type !== 'organization' && !birthDate) {
        return { birthDateRequired: true };
      }
      return null;
    };
  }

  isFieldInvalid(field: string): any {
    const control = this.form.get(field);
    return control?.invalid && control?.touched;
  }

  getFormError(field: string, errorType: string): boolean {
    const control = this.form.get(field);
    return control?.errors?.[errorType];
  }

  fetchClient(term: string): void {
    this.clientService.searchClients(term).subscribe({
      next: (response) => {
        this.alertService.close();
        if (response.success) {
          const clientData: any[] = response.data;
          this.clients = clientData.filter(
            (client) =>
              client.type === 'individual' || client.type === 'self-employed'
          );
          this.filteredClients = this.clients.map((client) => ({
            ...client,
            displayName: `${client.name} ${client.lastname || ''}`.trim(),
          }));
        } else {
          console.error('Error en la respuesta del servidor:', response);
        }
      },
      error: (error) =>
        console.error('Hubo un error al obtener los datos:', error),
    });
  }

  emitAddClient() {
    this.addClient.emit(true);
  }

  selectedTaxId: string = '';
  showAdditionalFields: boolean = false;
  isDuplicated: boolean = false;

  onNextStep(): void {
    this.selectedTaxId = this.form.get('tax_id')?.value || '';
    if (this.selectedTaxId) {
      this.form.get('tax_id');
      this.showAdditionalFields = true;
    } else {
      console.warn('El campo Tax ID está vacío');
    }
  }

  changeBirthplace(birthplace: string) {
    this.form.get('birthplace_country')?.setValue(birthplace);
  }

  changePrefix(event: any) {
    this.form.get('phonePrefix')?.setValue(event);
  }
  @Output() closeAssigneeCreateForm = new EventEmitter<void>();
  @Output() assigneeCreated = new EventEmitter<any>();
  onSubmit() {
    this.alertService.loading('swal_messages.creating_client');

    let formData = { ...this.form.value };
    formData.tax_id_temp = this.form.get('tax_id_temp')?.value;
    formData.tax_id = this.form.get('tax_id')?.value;

    const birthDate = this.form.get('birth_date')?.value;
    if (birthDate) {
      const formattedDate = this.convertToLocalDate(new Date(birthDate));
      formData.birth_date = formattedDate;
    }

    const expirationDate = this.form.get('document_expiration_date')?.value;
    if (expirationDate) {
      const formattedDate = this.convertToLocalDate(new Date(expirationDate));
      formData.document_expiration_date = formattedDate;
    }

    let aux = this.form.get('phoneNumber')?.value;
    if (aux) {
      let phonePrefix = this.form.get('phonePrefix')?.value || '+34';
      const phoneNumber = this.form.get('phoneNumber')?.value;
      const combinedPhoneNumber = `(${phonePrefix})${phoneNumber}`;
      formData.phone = combinedPhoneNumber;
    }

    this.clientService.addData(formData).subscribe(
      (response) => {
        this.alertService.successOk('swal_messages.client_created');
        this.emitAddClient();

        this.resetForm();
        console.log(' this.isAssigneeMode', this.isAssigneeMode);
        if (this.isAssigneeMode) {
          this.closeAssigneeCreateForm.emit(response);
        }
        this.emitBackButtonClick();
        this.fetchClient('');
        this.search$.next('');
        this.loadIndividualClients();
        this.filteredClients = [...this.filteredClients];
        this.loadIndividualClients();
        this.clientSelector.refreshItems();
        this.reloadClientsList();
      },
      (error) => {
        this.alertService.error(error.error);
      }
    );
  }
  reloadClientsList(): void {
    this.fetchClient('');
    this.filteredClients = [...this.filteredClients];
  }

  @Input() isAssigneeMode = false;

  resetForm(): void {
    this.form.reset({
      status: true,
      type: '',
      tax_id: '',
      assignee_id: '',
      name: '',
      lastname: '',
      email: '',
      phone: '',
      billing_address: '',
      billing_postal_code: '',
      billing_city: '',
      billing_state: '',
      phoneNumber: '',
      phonePrefix: '+34',
      birthplace_country: 'spain',
      nationality: '',
    });
    this.step = 1;
    this.showAdditionalFields = false;
    this.taxIdExists = false;
    this.canProceed = false;
    this.files = [];
    this.changePrefix('+34');
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  emitBackButtonClick() {
    this.resetForm();
    this.backButtonClick.emit(true);
  }

  emitBackButtonClick2() {
    if (this.isAssigneeMode) {
      this.isAssigneeMode = false;
      this.backButtonClick2.emit(true);
    }
  }
  getSelectedClientType(): string | null {
    return this.form.get('type')?.value || null;
  }

  get phone() {
    return this.form.get('phone');
  }

  nameValue: string | null = null;
  lastnameValue: string | null = null;
  individualClients: any[] = [];
  selectedClientType: string | null = null;

  loadIndividualClients(): void {
    this.clientService.getData().subscribe({
      next: (response) => {
        this.alertService.close();
        if (response.success) {
          const clientData: any[] = response.data;
          this.clients = clientData.filter(
            (client) =>
              client.type === 'individual' || client.type === 'self-employed'
          );
          this.filteredClients = this.clients.map((client) => ({
            ...client,
            displayName: `${client.name} ${client.lastname || ''}`.trim(),
          }));
        } else {
          console.error('Error en la respuesta del servidor:', response);
        }
      },
      error: (error) =>
        console.error('Hubo un error al obtener los datos:', error),
    });
  }

  searchClients(term: string): Observable<
    Array<{
      name: string;
      reference: string;
      tax_id: string;
      lastname?: string;
    }>
  > {
    return new Observable((observer) => {
      term = term || '';
      if (!term.trim()) {
        observer.next([]);
      } else {
        const filtered = this.clients.filter((client) => {
          const nameMatches = client.name
            ? client.name.toLowerCase().includes(term.toLowerCase())
            : false;
          const taxIdMatches = client.tax_id
            ? client.tax_id.toLowerCase().includes(term.toLowerCase())
            : false;
          const fullnameMatches = client.lastname
            ? `${client.name} ${client.lastname}`
                .toLowerCase()
                .includes(term.toLowerCase())
            : false;
          return nameMatches || taxIdMatches || fullnameMatches;
        });
        observer.next(filtered);
      }
    });
  }

  onSearch(event: any): void {
    const term = event.term || '';
    this.search$.next(term);
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;

    if (selectedDate) {
      const adjustedDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      );
      this.form.get('birth_date')?.setValue(adjustedDate);
    }
  }

  onDateChange2(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;

    if (selectedDate) {
      const adjustedDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      );
      this.form.get('document_expiration_date')?.setValue(adjustedDate);
    }
  }
  convertToLocalDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  }

  goToForm() {
    this.step = 2;
    this.showAdditionalFields = true;
    this.canProceed = true;
  }

  fillFormWithClientData(clientData: any): void {
    if (clientData.tax_id?.startsWith('TEMP-')) {
      this.form.patchValue({
        tax_id_temp: clientData.tax_id,
        tax_id: '',
      });
    } else {
      this.form.patchValue({
        tax_id: clientData.tax_id,
        tax_id_temp: clientData.tax_id,
      });
    }
    this.form.patchValue({
      type: clientData.type || '',
      name: clientData.name || '',
      lastname: clientData.lastname || '',
      email: clientData.email || '',
      phone: clientData.phone || '',
      billing_address: clientData.billing_address || '',
      billing_postal_code: clientData.billing_postal_code || '',
      billing_city: clientData.billing_city || '',
      billing_state: clientData.billing_state || '',
      phonePrefix: clientData.phonePrefix || '+34',
      phoneNumber: clientData.phoneNumber || '',
      document_expiration_date: clientData.document_expiration_date
        ? new Date(clientData.document_expiration_date)
        : null,
      birth_date: clientData.birth_date
        ? new Date(clientData.birth_date)
        : null,
    });

    console.log(clientData.tax_id);
  }

  files: FileItem[] = [];
  uploadedFiles: File[] = [];

  selectOptionChanged(event: Event, index: number) {
    const selectElement = event.target as HTMLSelectElement;
    const option = selectElement.value;
    this.files[index].selectOption = option;
  }

  showFormEdit = false;

  @Output() clientDuplicateFound = new EventEmitter<string>();

  onDuplicateFound() {
    if (this.existingClientReference) {
      this.clientDuplicateFound.emit(this.existingClientReference);
    }
    this.resetForm();
  }
  /*******************/

  isDragOver = false;
  isUploading = false;
  canProceed = false;
  uploadComplete = false;
  isCheckingTaxId = false;

  getThumbnailSrc(file: File): string {
    const patterns = {
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      pdf: 'application/pdf',
      doc: 'application/msword',
      csv: 'text/csv',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      xslx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'word-openxml':
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      xls: 'application/vnd.ms-excel',
      'xls-openxml':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    for (const [key, mimeType] of Object.entries(patterns)) {
      if (file.type === mimeType) {
        switch (key) {
          case 'jpg':
          case 'jpeg':
          case 'png':
            return `../../../assets/icons/jpg.svg`;
            break;
          case 'pdf':
            return `../../../assets/icons/pdf.svg`;
            break;
          case 'word-openxml':
          case 'doc':
          case 'docx':
            return `../../../assets/icons/doc.svg`;
            break;
          case 'xls-openxml':
          case 'xls':
            return `../../../assets/icons/xls.svg`;
            break;
          default:
            return '../../../assets/icons/file.svg';
            break;
        }
      }
    }
    return '../../../assets/icons/file.svg';
  }

  removeFile(index: number): void {
    this.files.splice(index, 1);
    this.canProceed = this.files.length > 0;

    if (this.files.length === 0) {
      this.resetForm();
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;
  }

  addFiles(files: FileList): void {
    for (const file of Array.from(files)) {
      if (
        !this.files.some(
          (f) => f.file.name === file.name && f.file.size === file.size
        )
      ) {
        const newFileItem: FileItem = {
          file,
          selectOption: 'defaultOption',
          key: 'defaultOption',
        };
        this.files.push(newFileItem);
      }
    }
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;
    const droppedFiles = event.dataTransfer?.files;
    if (droppedFiles) {
      this.handleFilesUpload(droppedFiles);
    }
  }

  checkTaxId(taxId: string): void {
    const normalizedTaxId = taxId.toUpperCase();
    this.isCheckingTaxId = true;

    this.clientService.checkTaxId(normalizedTaxId).subscribe({
      next: (response) => {
        if (response.success && response.data?.reference) {
          this.taxIdExists = true;
          this.existingClient = response.data;
          this.showAlertFilter = true;
          this.isDuplicated = true;
          this.existingClientReference = response.data.reference;
          this.canProceed = false;
        } else {
          this.resetDuplicateStatus();
        }
      },
      error: (error) => {
        console.error('Error al verificar el Tax ID:', error);
        this.resetDuplicateStatus();
      },
      complete: () => {
        this.isCheckingTaxId = false;
        this.updateCanProceed();
      },
    });
  }

  resetDuplicateStatus(): void {
    this.taxIdExists = false;
    this.showAlertFilter = false;
    this.isDuplicated = false;
    this.existingClient = null;
    this.existingClientReference = '';
  }

  onTaxIdChange(): void {
    const taxIdControl = this.form.get('tax_id');
    if (taxIdControl) {
      const upperTaxId = taxIdControl.value.toUpperCase();
      taxIdControl.setValue(upperTaxId, { emitEvent: false });
      this.checkTaxId(upperTaxId);
    }
  }

  updateCanProceed(): void {
    this.canProceed =
      (!this.taxIdExists && !this.isCheckingTaxId) || this.uploadComplete;
  }

  onFileInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (files) {
      this.handleFilesUpload(files);
    }
  }

  handleFilesUpload(files: FileList): void {
    if (files && files.length > 0) {
      const formData = new FormData();
      Array.from(files).forEach((file) => formData.append('file[]', file));

      this.alertService.loading('swal_messages.please_wait');

      this.clientService.uploadDocument(formData).subscribe({
        next: (response) => {
          console.log('Datos recibidos:', response.data);
          this.addFiles(files);
          this.alertService.close();

          const clientData = response.data;
          if (clientData.tax_id) {
            this.form.patchValue(
              { tax_id: clientData.tax_id },
              { emitEvent: true }
            );
            this.fillFormWithClientData(clientData);
            this.showAdditionalFields = true;
          }
          this.uploadComplete = true;
          this.isUploading = false;
          this.updateCanProceed();
        },
        error: (error) => {
          console.error('Error al subir el documento:', error);
          this.alertService.error(error.error);
          this.fileInput.nativeElement.value = '';
        },
      });
    }
  }

  onBirthplaceCountrySelected(selectedCountryName: string) {
    this.form.get('birthplace_country')?.setValue(selectedCountryName);
  }

  onPhonePrefixSelected(selectedPrefix: string) {
    this.form.get('phone_prefix')?.setValue(selectedPrefix);
  }

  @Input() document_buttons_chico = false;
  @Output() emitOpenAssigneeCreateForm = new EventEmitter<boolean>();

  openAssigneeCreateForm() {
    this.document_buttons_chico = true;
    this.emitOpenAssigneeCreateForm.emit();
  }
  closeAssigneeForm(): void {
    this.isAssigneeMode = false;
    console.log('Cerrando solo el formulario secundario');
  }
}
