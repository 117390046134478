import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule, Location } from '@angular/common';
import { Component, inject, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import {
  MatPaginatorModule,
  MatPaginator,
  PageEvent,
} from '@angular/material/paginator';

import { AlertService } from '../../core/services/alert/alert.service';
import { AccountsService } from '../../core/services/accounts/accounts.service';
import { LocalStorageService } from '../../core/services/localStorage/local-storage.service';
//import { FilterComponent } from './filter/filter.component';
import { Invoices } from '../../core/interfaces/invoices';
import { UsersService } from '../../core/services/users/users.service';
import { FormAddUsersComponent } from "./form-add-users/form-add-users.component";

interface AccountData {
  reference: string;
  name: string;
  type: string;
  tax_id: string;
  billing_address: string;
  billing_city: string;
  billing_state: string;
  root_account: any; // ajusta según la estructura real de root_account si es necesario
  status: boolean;
}
interface ApiResponse {
  data: AccountData[];
  // Otros campos posibles como error, message, etc.
}
@Component({
  selector: 'app-users',
  standalone: true,
  imports: [CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule, FormAddUsersComponent],
  templateUrl: './users.component.html',
  styleUrl: './users.component.css'
})
export class UsersComponent {
  private route = inject(ActivatedRoute);
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  showForm = false;
  showAddUserForm = false;
  showClear = false;

  pageNumber: number = 1;
  pageSize: number = 100;

  user_reference?: any;
  userData?: any;

  dataSource = new MatTableDataSource<Invoices>();
  title = 'users';

  showAlertFilter = false;
  showCountFilter = 0;
  showAlertFilterAll = false;
  searchParams: any = {};
  accountType: string | null = null;

  ladata: any = [];
  cargaladata: any = [];

  document_buttons_chico = false;
  tab = 'general';


  displayedColumns: string[] = [
    'name',
    'lastname',
    'roles',
    'phone',
    'email',
    'img',
    'status',
    'actions',
  ];

  type: any = 'broker';
  autoFetch: any;

  @Input() selectTypes: any[] = [];

  // Mapa para relacionar rutas con sus títulos y tipos de orden
  routesMap: { [key: string]: string } = {
    users: '',
    'users/all': '',
    'users/paid': 'paid',
    'users/drafts': 'drafts',
    'users/pending': 'pending',
    'users/delivered': 'delivered',
    'users/cancelled': 'cancelled',
  };

  constructor(
    private location: Location,
    private titleService: Title,
    private alertService: AlertService,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private usersService: UsersService,
  ) {

  }

  ngOnInit() {
    // // this.alertService.loading('swal_messages.please_wait');
    this.detectReference(),
      this.alertService.loading('swal_messages.please_wait');
    this.fetchAllData();
    this.startAutoFetch();
  }

  startAutoFetch(): void {
    if (this.autoFetch) {
      clearInterval(this.autoFetch);
    }
    // this.autoFetch = setInterval(() => {
    //   this.refreshUserData();
    // }, 40000);
  }

  detectReference() {

    // Obtener el tipo de cuenta del localStorage
    this.getAccountTypeFromLocalStorage();

    // Obtener la URL actual
    const nuevaURL = this.location.path();



    // Obtener la referencia de la orden
    let paramReference = this.route.snapshot.paramMap.get('reference');
    if (paramReference) {
      this.user_reference = paramReference;
      const updatedURL = `/users/`;
      this.location.go(updatedURL);
      this.getUser(paramReference);
    }
  }

  private fetchAllData() {
    Promise.all([
      this.fetchData(),
    ]).then(() => {
      this.ladata = this.cargaladata;
      setTimeout(() => {
        this.alertService.close();
      }, 500);
    });
  }

  expandedRow: any = null;

  toggleRow(row: any): void {
    //this.expandedRow = this.expandedRow === row ? null : row;
    this.expandedRow = row;
  }

  fetchData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.alertService.loading('swal_messages.please_wait');
      this.usersService
        .getData()
        .subscribe({
          next: (data) => {
            this.dataSource.data = data.data;
            this.cargaladata.flag = true;
            this.dataSource.sort = this.sort;
            //this.pageNumber = data.meta.current_page;
            if (!this.localStorageService.getItem('per_page')) {
              this.localStorageService.setItem('per_page', data.meta.per_page);
              this.pageSize = data.meta.per_page;
            } else {
              this.pageSize = Number(
                this.localStorageService.getItem('per_page')
              );
            }
            this.paginator.pageSizeOptions = [25, 50, 100];
            this.paginator.pageSize = this.pageSize;
            this.paginator.pageIndex = this.pageNumber - 1;
            // this.paginator.length = data.meta.total;
            this.alertService.close();
            setTimeout(() => {
              resolve();
            }, 500);
          },
          error: (error) => {
            this.alertService.error(error.error);
            reject(error);
          },
        });
    });
  }

  getUser(reference: string): void {
    this.alertService.loading('swal_messages.please_wait');
    const nuevaURL = this.location.path() + '/' + reference;
    this.location.go(nuevaURL);

    this.user_reference = reference;

    this.usersService.searchUser(this.user_reference).subscribe({
      next: (data) => {
        this.userData = data.data;
        let title = data.data?.name ? data.data?.name + ' ' + data.data?.lastname : data.data?.reference;
        this.titleService.setTitle(
          this.translate.instant('pages.titles.' + this.title) +
          ' ' +
          title +
          '- DBM'
        );
        this.showForm = true;
        this.alertService.close();
      },
      error: (error) => {
        this.alertService.error('swal_messages.order_not_found');
      },
    });
  }


  toggleForm() {
    this.showForm = !this.showForm;
  }

  getAccountTypeFromLocalStorage(): void {
    this.accountType = localStorage.getItem('account_type');
  }

  cerrarFormulario() {
    this.titleService.setTitle(
      this.translate.instant('pages.titles.users')
    );
    const nuevaURL = this.location.path() + '/users/';
    const index = nuevaURL.indexOf('/');
    this.location.go(
      nuevaURL.substring(0, index) + '/users/');
    this.userData = null;
    this.showForm = false;
    this.showAddUserForm = false;
    this.document_buttons_chico = false;
  }

  refrescar() {
    this.fetchData();
  }

  // refreshUserData() {
  //   this.invoiceService
  //     .getFilterData(
  //       this.typeInvoices,
  //       this.pageNumber,
  //       this.pageSize,
  //       this.searchParams
  //     )
  //     .subscribe({
  //       next: (data) => {
  //         this.dataSource.data = data.data;
  //         this.dataSource.sort = this.sort;
  //         this.pageNumber = data.meta.current_page;
  //         if (!this.localStorageService.getItem('per_page')) {
  //           this.localStorageService.setItem('per_page', data.meta.per_page);
  //           this.pageSize = data.meta.per_page;
  //         } else {
  //           this.pageSize = Number(
  //             this.localStorageService.getItem('per_page')
  //           );
  //         }
  //         this.paginator.pageSizeOptions = [25, 50, 100];
  //         this.paginator.pageSize = this.pageSize;
  //         this.paginator.pageIndex = this.pageNumber - 1;
  //         this.paginator.length = data.meta.total;
  //         this.showCountFilter = data.meta.total;

  //         if (Object.keys(this.searchParams).length === 0) {
  //           this.showAlertFilter = false;
  //         } else {
  //           this.showAlertFilter = true;
  //         }
  //       },
  //       error: (error) => {
  //         this.alertService.error(error.error);
  //       },
  //     });
  // }

  /******* EVENTO DEL FILTRO  *******/
  // filterChanged(searchTerm: any) {
  //   this.alertService.loading('swal_messages.please_wait');
  //   this.searchParams = searchTerm;
  //   this.invoiceService
  //     .getFilterData(
  //       this.typeInvoices,
  //       this.pageNumber,
  //       this.pageSize,
  //       this.searchParams
  //     )
  //     .subscribe({
  //       next: (data) => {
  //         this.dataSource.data = data.data;
  //         this.dataSource.sort = this.sort;
  //         this.pageNumber = data.meta.current_page;
  //         if (!this.localStorageService.getItem('per_page')) {
  //           this.localStorageService.setItem('per_page', data.meta.per_page);
  //           this.pageSize = data.meta.per_page;
  //         } else {
  //           this.pageSize = Number(
  //             this.localStorageService.getItem('per_page')
  //           );
  //         }
  //         this.paginator.pageSizeOptions = [25, 50, 100];
  //         this.paginator.pageSize = this.pageSize;
  //         this.paginator.pageIndex = this.pageNumber - 1;
  //         this.paginator.length = data.meta.total;
  //         this.showCountFilter = data.meta.total;
  //         if (Object.keys(searchTerm).length === 0) {
  //           this.showAlertFilter = false;
  //         } else {
  //           this.showAlertFilter = true;
  //         }
  //         this.startAutoFetch();
  //         this.alertService.close();
  //       },
  //       error: (error) => {
  //         this.alertService.error(error.error);
  //       },
  //     });
  // }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex + 1;
    this.localStorageService.setItem('per_page', String(event.pageSize));
    this.pageSize = event.pageSize;
    //this.fetchData();
  }

  getBadgeColor(
    stage: string,
    requiere_action: string,
    priority: string
  ): string {
    const statusColorMap: { [key: string]: string } = {
      true: 'text-no-action',
      false: 'text-canceled',
    };


    // Default color if none of the conditions match
    return statusColorMap[stage] ? statusColorMap[stage] : 'text-no-action';
  }

  getBadgeColorClient(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      organization: 'text-organization',
      'self-employed': 'text-self-employed',
      individual: 'text-individual',
    };

    return statusColorMap[status] || 'text-individual';
  }

  refreshUser() { }



  cerrarUserFormulario() {
    this.document_buttons_chico = false;
    this.showAddUserForm = false;
  }

  OpenFormAddUsers() {
    this.showAddUserForm = true;
    this.document_buttons_chico = true;
  }

  CloseFormAddUsers() {
    this.document_buttons_chico = false;
    this.showAddUserForm = false;
  }
}
