<div class="d-flex">
  <app-tabs-form [activeForm]="activeForm" [draft]="draft" (changeForm)="changeForm($event)" [role]="role"
    [updatesTabs]="updatesTabs" [document_buttons_chico]="document_buttons_chico"></app-tabs-form>

  <div class="card w-100 form-right">
    <app-helper-modal *ngIf="helper" #helperModal (optionSelected)="handleOption($event)" (closeHelper)="closeHelper()"
      [step]="helperStep" [orderData]="orderData" [brand_id_modal]="brand_id_modal">
    </app-helper-modal>
    <div class="back">
      <h2 *ngIf="!draft">{{ "forms.names.addorder" | translate }}</h2>
      <div *ngIf="draft" class="div-title">

        <h2>
          {{ title }}<i (click)="showImportantDates = !showImportantDates" style="cursor: pointer" class="m-2"
            [ngClass]="{
           'icon-chevron-up-circle' : showImportantDates,
            'icon-clock' : !showImportantDates
          }"></i>
        </h2>
        <div *ngIf="showImportantDates" class="div-clocks text-secondary">
          <span class="clocks" *ngIf="orderData?.date_sent"> <i class="icon-clock"> </i>{{
            "badge.sent" | translate
            }} {{orderData.date_sent}} </span>
          <span class="clocks" *ngIf="orderData?.date_risk_started"> <i class="icon-clock"></i> {{
            "badge.risk_started" | translate
            }} {{orderData.date_risk_started}} </span>
          <span class="clocks" *ngIf="orderData?.date_contract_signed"> <i class="icon-clock"> </i>{{
            "badge.contract_signed" | translate
            }} {{orderData.date_contract_signed}} </span>
          <span class="clocks" *ngIf="orderData?.date_delivered"> <i class="icon-clock"> </i>{{
            "badge.delivered" | translate
            }} {{orderData.date_delivered}} </span>
        </div>
        <span class="badge text-badge" [ngClass]="getBadgeColor(stage,orderData.require_action,orderData.priority)">{{
          "badge." + stage | translate
          }}</span>
        <span *ngIf="orderData.client?.name" class="badge text-badge"
          [ngClass]="getBadgeColorClient(orderData.client?.type)">{{
          "forms.inputs." + orderData.client?.type | translate
          }}</span>
        <span *ngIf="orderData?.guarantee?.name" class="badge text-badge"
          [ngClass]="getBadgeColorClient('guarantee')">{{
          "forms.inputs.with_guarantee" | translate
          }}</span>
      </div>
      <i class="icon-cross2" (click)="emitBackButtonClick()"></i>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="form">
          <form [formGroup]="form">
            <div [hidden]="activeForm !== 'general'">
              <app-general-form [orderData]="orderData" [ladata]="ladata"
                (emitSubmit)="onSubmitOrderFast($event)"></app-general-form>
            </div>
            <div *ngIf="draft">
              <div [hidden]="activeForm !== 'vehicle'">
                <app-vehicle-form [orderData]="orderData" [ladata]="ladata" (emitDuplicate)="duplicateOrder()"
                  (emitChangeBrand)="emitChangeBrand($event)"></app-vehicle-form>
              </div>
              <div [hidden]="activeForm !== 'currency'">
                <app-contract-form [orderData]="orderData" [ladata]="ladata"></app-contract-form>
              </div>
              <div [hidden]="activeForm !== 'client'" class="accountData">
                <app-client-form [orderData]="orderData" [ladata]="ladata"
                  (emitOpenAssigneeForm)="openAssigneeForm($event)"
                  (emitOpenClientForm)="openClientForm()"></app-client-form>
              </div>
              <div [hidden]="activeForm !== 'guarantee'" class="accountData">
                <app-guarantee-form [orderData]="orderData" [ladata]="ladata"
                  (emitOpenAssigneeForm)="openAssigneeForm($event)"
                  (emitOpenClientForm)="openClientForm()"></app-guarantee-form>
              </div>
              <div [hidden]="!['client', 'doc-order', 'guarantee'].includes(activeForm)" class="accountData">
                <app-form-add-document [orderData]="orderData" [order_reference]="order_reference"
                  [client_reference]="client_reference" [client]="client" [guarantee_reference]="guarantee_reference"
                  [guarantee]="guarantee" [selectTypes]="ladata.selectTypes" [activeForm]="activeForm" [rules]="rules"
                  (filesArrayChanged)="actualizarEstadoArchivos($event)" (goDocs)="goDocs()"
                  (formSubmit)="refrescar()"></app-form-add-document>
              </div>
              <div [hidden]="activeForm !== 'risk'">
                <app-risk-form [orderData]="orderData" [ladata]="ladata"></app-risk-form>
              </div>
              <div [hidden]="activeForm !== 'delivery'">
                <app-delivery-form [orderData]="orderData" [ladata]="ladata"></app-delivery-form>
              </div>
              <div [hidden]="activeForm !== 'commission'">
                <app-commission-form [orderData]="orderData" [ladata]="ladata"></app-commission-form>
              </div>
              <div [hidden]="activeForm !== 'notes'">
                <app-form-add-notes [order_reference]="order_reference"
                  [require_action_note]="orderData.require_action_note" [activeForm]="activeForm"
                  (updateStage)="refrescar()"></app-form-add-notes>
              </div>
              <div *ngIf="activeForm == 'timeline'">
                <app-timeline [order_reference]="order_reference"></app-timeline>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="div-form"></div>

      <div class="btn-send d-flex align-items-center justify-content-between w-100 ml-4"
        *ngIf="activeForm != 'timeline'">
        <div class="clear">
          <button *ngIf="activeForm == 'general' && back != ''" class="btn btn-hover clear-button  ml-15" type="button"
            (click)="onSubmitOrderFast(back)">
            <i class="icon-chevron-left"></i> {{ "buttons.back_stage" | translate }} {{ "forms.inputs.status_select." +
            back | translate }}
          </button>
        </div>
        <button *ngIf="!draft" type="submit" class="btn btn-primary btn-primary-form" (click)="onSubmit()">

          <span *ngIf="role == 'Sales'">
            {{ "buttons.createsql" | translate }}
          </span>
          <span *ngIf="role != 'Sales'">
            {{ "buttons.createdraft" | translate }}
          </span>
        </button>
        <button *ngIf="draft && !isDocumentForm()" type="submit" class="btn btn-primary btn-primary-form"
          (click)="onSubmitDraft()">
          <span>
            {{ "buttons.update_order" | translate }}
          </span>
        </button>
        <button *ngIf="draft && isDocumentForm()" type="submit" class="btn btn-primary btn-primary-form"
          (click)="onSubmitDraft()">
          <span *ngIf="docFiles.length == 0">
            {{ "buttons.update_order" | translate }}
          </span>
          <span *ngIf="docFiles.length > 0">
            {{ "buttons.upload_" + activeForm | translate }}
            <i class="icon-upload2"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
