import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-select-country-prefix',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './select-country-prefix.component.html',
  styleUrl: './select-country-prefix.component.css',
})
export class SelectCountryPrefixComponent implements OnInit {
  @Input() selectedPrefix: string = '+34';
  @Input() showPhonePrefix: boolean = true;
  @Output() changePrefix = new EventEmitter<any>();
  @Output() changeBirthplace = new EventEmitter<any>();

  europeanCountries = [
    {
      name: 'Spain',
      flag: 'https://flagcdn.com/w40/es.png',
      phonePrefix: '+34',
    },
    {
      name: 'Germany',
      flag: 'https://flagcdn.com/w40/de.png',
      phonePrefix: '+49',
    },
    {
      name: 'France',
      flag: 'https://flagcdn.com/w40/fr.png',
      phonePrefix: '+33',
    },
    {
      name: 'Italy',
      flag: 'https://flagcdn.com/w40/it.png',
      phonePrefix: '+39',
    },
    {
      name: 'United Kingdom',
      flag: 'https://flagcdn.com/w40/gb.png',
      phonePrefix: '+44',
    },
    {
      name: 'Netherlands',
      flag: 'https://flagcdn.com/w40/nl.png',
      phonePrefix: '+31',
    },
    {
      name: 'Belgium',
      flag: 'https://flagcdn.com/w40/be.png',
      phonePrefix: '+32',
    },
    {
      name: 'Sweden',
      flag: 'https://flagcdn.com/w40/se.png',
      phonePrefix: '+46',
    },
    {
      name: 'Norway',
      flag: 'https://flagcdn.com/w40/no.png',
      phonePrefix: '+47',
    },
    {
      name: 'Denmark',
      flag: 'https://flagcdn.com/w40/dk.png',
      phonePrefix: '+45',
    },
    {
      name: 'Finland',
      flag: 'https://flagcdn.com/w40/fi.png',
      phonePrefix: '+358',
    },
    {
      name: 'Ireland',
      flag: 'https://flagcdn.com/w40/ie.png',
      phonePrefix: '+353',
    },
    {
      name: 'Portugal',
      flag: 'https://flagcdn.com/w40/pt.png',
      phonePrefix: '+351',
    },
    {
      name: 'Greece',
      flag: 'https://flagcdn.com/w40/gr.png',
      phonePrefix: '+30',
    },
    {
      name: 'Austria',
      flag: 'https://flagcdn.com/w40/at.png',
      phonePrefix: '+43',
    },
    {
      name: 'Switzerland',
      flag: 'https://flagcdn.com/w40/ch.png',
      phonePrefix: '+41',
    },
    {
      name: 'Poland',
      flag: 'https://flagcdn.com/w40/pl.png',
      phonePrefix: '+48',
    },
    {
      name: 'Czech Republic',
      flag: 'https://flagcdn.com/w40/cz.png',
      phonePrefix: '+420',
    },
    {
      name: 'Hungary',
      flag: 'https://flagcdn.com/w40/hu.png',
      phonePrefix: '+36',
    },
    {
      name: 'Romania',
      flag: 'https://flagcdn.com/w40/ro.png',
      phonePrefix: '+40',
    },
    {
      name: 'Bulgaria',
      flag: 'https://flagcdn.com/w40/bg.png',
      phonePrefix: '+359',
    },
    {
      name: 'Croatia',
      flag: 'https://flagcdn.com/w40/hr.png',
      phonePrefix: '+385',
    },
    {
      name: 'Slovakia',
      flag: 'https://flagcdn.com/w40/sk.png',
      phonePrefix: '+421',
    },
    {
      name: 'Slovenia',
      flag: 'https://flagcdn.com/w40/si.png',
      phonePrefix: '+386',
    },
    {
      name: 'Lithuania',
      flag: 'https://flagcdn.com/w40/lt.png',
      phonePrefix: '+370',
    },
    {
      name: 'Latvia',
      flag: 'https://flagcdn.com/w40/lv.png',
      phonePrefix: '+371',
    },
    {
      name: 'Estonia',
      flag: 'https://flagcdn.com/w40/ee.png',
      phonePrefix: '+372',
    },
    {
      name: 'Luxembourg',
      flag: 'https://flagcdn.com/w40/lu.png',
      phonePrefix: '+352',
    },
    {
      name: 'Iceland',
      flag: 'https://flagcdn.com/w40/is.png',
      phonePrefix: '+354',
    },
    {
      name: 'Malta',
      flag: 'https://flagcdn.com/w40/mt.png',
      phonePrefix: '+356',
    },
    {
      name: 'Cyprus',
      flag: 'https://flagcdn.com/w40/cy.png',
      phonePrefix: '+357',
    },
    {
      name: 'Montenegro',
      flag: 'https://flagcdn.com/w40/me.png',
      phonePrefix: '+382',
    },
    {
      name: 'Serbia',
      flag: 'https://flagcdn.com/w40/rs.png',
      phonePrefix: '+381',
    },
    {
      name: 'Bosnia and Herzegovina',
      flag: 'https://flagcdn.com/w40/ba.png',
      phonePrefix: '+387',
    },
    {
      name: 'Macedonia',
      flag: 'https://flagcdn.com/w40/mk.png',
      phonePrefix: '+389',
    },
    {
      name: 'Albania',
      flag: 'https://flagcdn.com/w40/al.png',
      phonePrefix: '+355',
    },
    {
      name: 'Mexico',
      flag: 'https://flagcdn.com/w40/mx.png',
      phonePrefix: '+52',
    },
    {
      name: 'Argentina',
      flag: 'https://flagcdn.com/w40/ar.png',
      phonePrefix: '+54',
    },
    {
      name: 'Brazil',
      flag: 'https://flagcdn.com/w40/br.png',
      phonePrefix: '+55',
    },
    {
      name: 'Chile',
      flag: 'https://flagcdn.com/w40/cl.png',
      phonePrefix: '+56',
    },
    {
      name: 'Colombia',
      flag: 'https://flagcdn.com/w40/co.png',
      phonePrefix: '+57',
    },
    {
      name: 'Ecuador',
      flag: 'https://flagcdn.com/w40/ec.png',
      phonePrefix: '+593',
    },
    {
      name: 'Paraguay',
      flag: 'https://flagcdn.com/w40/py.png',
      phonePrefix: '+595',
    },
    {
      name: 'Peru',
      flag: 'https://flagcdn.com/w40/pe.png',
      phonePrefix: '+51',
    },
    {
      name: 'Uruguay',
      flag: 'https://flagcdn.com/w40/uy.png',
      phonePrefix: '+598',
    },
    {
      name: 'Venezuela',
      flag: 'https://flagcdn.com/w40/ve.png',
      phonePrefix: '+58',
    },
    {
      name: 'Bolivia',
      flag: 'https://flagcdn.com/w40/bo.png',
      phonePrefix: '+591',
    },

    {
      name: 'United States',
      flag: 'https://flagcdn.com/w40/us.png',
      phonePrefix: '+1',
    },
    {
      name: 'Mexico',
      flag: 'https://flagcdn.com/w40/mx.png',
      phonePrefix: '+52',
    },
  ];

  selectedCountry: any;

  ngOnInit() {
    this.updateSelectedCountry();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectionMode === 'prefix' && changes['selectedPrefix']) {
      const matchingCountry = this.europeanCountries.find(
        (country) =>
          country.phonePrefix === changes['selectedPrefix'].currentValue
      );
      if (matchingCountry) {
        this.selectedCountry = matchingCountry;
        this.selectedCountryName = matchingCountry.name;
      }
    }

    if (this.selectionMode === 'name' && changes['selectedCountryName']) {
      this.selectedCountry = this.europeanCountries.find(
        (country) =>
          country.name === changes['selectedCountryName'].currentValue
      );
    }
  }

  @Input() selectionMode: 'name' | 'prefix' = 'name';

  @Output() countrySelected = new EventEmitter<string>();
  @Input() selectedCountryName: string = '';

  onCountryChange(selectedCountryName: string) {
    this.selectedCountry = this.europeanCountries.find(
      (country) => country.name === selectedCountryName
    );

    if (this.selectedCountry) {
      if (this.selectionMode === 'prefix') {
        this.changePrefix.emit(this.selectedCountry.phonePrefix);
      } else {
        this.changeBirthplace.emit(this.selectedCountry.name);
      }
    }
  }

  updateSelectedCountry() {
    if (this.selectionMode === 'prefix' && this.selectedPrefix) {
      this.selectedCountry =
        this.europeanCountries.find(
          (country) => country.phonePrefix === this.selectedPrefix
        ) || this.europeanCountries[0];
    } else if (this.selectedCountryName) {
      this.selectedCountry =
        this.europeanCountries.find(
          (country) => country.name === this.selectedCountryName
        ) || this.europeanCountries[0];
    } else {
      this.selectedCountry = this.europeanCountries[0];
    }

    this.selectedCountryName = this.selectedCountry.name;

    if (this.selectionMode === 'prefix') {
      this.changePrefix.emit(this.selectedCountry.phonePrefix);
    } else {
      this.changeBirthplace.emit(this.selectedCountry.name);
    }
  }

  eventEmit(): void {
    this.changePrefix.emit(this.selectedCountry.phonePrefix);
    console.log(
      'this.selectedCountry.phonePrefix',
      this.selectedCountry.phonePrefix
    );
  }
  eventEmit2(): void {
    this.changeBirthplace.emit(this.selectedCountry.name);
    console.log('this.selectedCountry.name', this.selectedCountry.name);
  }

  onPrefixChange(newPrefix: string) {
    this.changePrefix.emit(newPrefix);
  }
}
