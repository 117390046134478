import { Routes } from '@angular/router';
import { TitleResolver } from '../resolver/metatitles/metatitles.resolver';
import { AutomationComponent } from '../../pages/automation/automation.component';
import { ProfileComponent } from '../../pages/profile/profile.component';
import { SettingComponent } from '../../pages/setting/setting.component';

export const settingRoutes: Routes = [
  {
    path: 'settings',
    component: SettingComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.settings' },
  },
  {
    path: 'settings/automation',
    component: AutomationComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.automations' },
  },
  {
    path: 'settings/profile',
    component: ProfileComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.profile' },
  },
  {
    path: 'settings/automation/:reference',
    component: AutomationComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.automations' },
  },
];
