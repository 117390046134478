<div class="encabezado">
  <div class="col-4 getBack "> <a routerLink="/reports" class="getBack"> <span><i class="icon-chevron-left"></i>{{
        "forms.buttons.back" | translate }} </span></a></div>
  <div class="">
    <div class="">
      <div>
        <h5 class=" titulo1">{{ type === 'contracts' ? ('reports.contracts' | translate) :
          ('reports.after-sales' | translate) }}</h5>
      </div>
    </div>
  </div>

  <div class="filter-content row">
    <app-filter [ladata]="ladata" (filterChanged)="filterChanged($event)"></app-filter>
  </div>

  <div class="tabla">
    <div class="col-12 row">
      <div class="col-2 p-1">
        <div class="reports-card">
          <div class="report-title">
            <p>{{ 'reports.orders' | translate }}</p>
          </div>
          <div class="text">
            <span>{{ totalOrders }}</span>
          </div>
        </div>
      </div>
      <div class="col-2 p-1">
        <div class="reports-card">
          <div class="report-title">
            <p>{{'reports.action_required' | translate}} </p>
          </div>
          <div class="text">
            <span>{{ requireActionTotal }}</span>
          </div>
        </div>
      </div>
      <div class="col-2 p-1">
        <div class="reports-card">
          <div class="report-title">
            <p>{{'reports.not_required_action' | translate}} </p>
          </div>
          <div class="text">
            <span>{{ notRequireActionTotal }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="chart-container  d-flex justify-content-center ">
    <canvas id="myChart"></canvas>
  </div>

  <div >
    <app-order-table [searchParamsClickBar]="searchParamsClickBar"></app-order-table>
  </div>


</div>

<div class="overlay" *ngIf="showForm"></div>
