<div *ngIf="isOpen" class="modal">
  <div class="modal-content">

    <div class="cross_close_helper"><i class="icon-cross2" (click)="closeModal()"></i></div>
    <div *ngIf="step == 1 ">
      <div class="title">
        <h3>{{"helper.suggestions1" | translate }} </h3>
      </div>

      <div class="modal-btn">
        <div *ngFor="let row of optionsModels" class="helper-btn" (click)="selectOption1(row)">
          <span class="helper-brand2"> {{ row.name }}</span>
        </div>
      </div>
    </div>

    <div *ngIf="step == 2 ">
      <div class="title">
        <h3>{{"helper.suggestions2" | translate }} </h3>
      </div>
      <div class="modal-btn" [ngClass]="{'grid3x2': optionsOffert.length > 3}">
        <div *ngFor="let row of optionsOffert.slice(0,6)" class="helper-btn" (click)="selectOption2(row)">
          <span class="helper-vehicle">{{ row.vehicle }}</span>
          <!-- <span class="helper-vehicle">{{ row.vehicle_suggested }}</span>
          <span class="helper-colour"> {{"helper.vehicle_model_year" | translate }} {{ row.vehicle_model_year
            }}</span>
          <span class="helper-colour"> {{"helper.colour" | translate }} {{ row.colour }}</span>
           <span class="helper-contract"> {{"helper.vehicle_status" | translate }} <span *ngIf="row?.vehicle_status"
              class="badge text-badge-list" [ngClass]="getBadgeColorVehicle(row.vehicle_status)">{{ "badge."
              + row?.vehicle_status | translate }}</span> </span> -->
          <span class="helper-colour"> {{ row.vehicle_model_year}} - {{ row.colour }} </span>
          <span class="helper-colour"> </span>
          <span class="helper-contract"> <span *ngIf="row?.vehicle_status" class="badge text-badge-list"
              [ngClass]="getBadgeColorVehicle(row.vehicle_status)">{{ "badge."
              + row?.vehicle_status | translate }}</span> </span>
        </div>
      </div>

    </div>

    <div *ngIf="step == 3 ">

      <div class="title">
        <h3>{{"helper.suggestions3" | translate }} </h3>
      </div>
      <div class="modal-btn" [ngClass]="{'grid3x2': optionsContracts.length > 3}">
        <div *ngFor="let row of optionsContracts.slice(0,9)" class="helper-btn" (click)="selectOption3(row)">
          <!-- <span class="helper-contract"> {{"helper.type" | translate }} : {{ "forms.inputs." + row.type | translate }}
          </span>
          <span class="helper-contract"> {{"helper.duration" | translate : { duration: row.duration } }} </span>

          <span *ngIf="row.monthly_fee_total" class="helper-contract"> {{"helper.monthly_fee_total" | translate: {
            monthly_fee_total: row.monthly_fee_total } }} </span>
          <span class="helper-value"> {{"helper.yearly_kms" | translate }} {{ row.yearly_kms }}</span>
          <span class="helper-includes">
            <i [ngClass]="row.include_insurance == '1' ? 'icon-check' : 'icon-cross2'">
            </i>{{"helper.include_insurance"
            | translate }}
            <i [ngClass]="row.include_maintenance == '1' ? 'icon-check' : 'icon-cross2'">
            </i>{{"helper.include_maintenance" | translate }}
            <i [ngClass]="row.include_replacement_vehicle == '1' ? 'icon-check' : 'icon-cross2'">
            </i>{{"helper.include_replacement_vehicle" | translate }}
            <i [ngClass]="row.include_tires == '1' ? 'icon-check' : 'icon-cross2'"> </i>{{"helper.include_tires" |
            translate }}
          </span> -->

          <span class="helper-contract">  {{row.duration}} / {{row.yearly_kms / 1000}} </span>
          <span class="helper-contract"> {{"helper.type" | translate }} : {{ "forms.inputs." + row.type | translate }}
          </span>
          <span *ngIf="row.monthly_fee_total" class="helper-contract"> {{row.monthly_fee_total}} €/mes </span>
          <!-- <span class="helper-includes">
            <i [ngClass]="row.include_insurance == '1' ? 'icon-check' : 'icon-cross2'">
            </i>{{"helper.include_insurance"
            | translate }}
            <i [ngClass]="row.include_maintenance == '1' ? 'icon-check' : 'icon-cross2'">
            </i>{{"helper.include_maintenance" | translate }}
            <i [ngClass]="row.include_replacement_vehicle == '1' ? 'icon-check' : 'icon-cross2'">
            </i>{{"helper.include_replacement_vehicle" | translate }}
            <i [ngClass]="row.include_tires == '1' ? 'icon-check' : 'icon-cross2'"> </i>{{"helper.include_tires" |
            translate }}
          </span> -->
        </div>
      </div>
    </div>

    <div class="row linea"></div>
    <div >
      <div class="div_breadcrumb d-flex">
        <span class="breadcrumb">
          <p class="p-breadcrumb" *ngIf="breadcrumb_brand" (click)="update_breadcrumb(1)">> {{breadcrumb_brand}} </p>
          <p class="p-breadcrumb" *ngIf="breadcrumb_model" (click)="update_breadcrumb(2)"> > {{breadcrumb_model}} </p>
          <p class="p-breadcrumb" *ngIf="breadcrumb_offert" (click)="update_breadcrumb(3)"> > {{breadcrumb_offert}} </p>
          <p class="p-breadcrumb" *ngIf="breadcrumb_contract" (click)="update_breadcrumb(4)"> > {{breadcrumb_contract}}
          </p>
        </span>


      </div>
      <div class="div_footer">
        <!-- <button class="btn btn-hover" (click)="closeModal()">Cerrar</button> -->
        <span class="close_helper text-secondary" (click)="closeModal()"> {{"helper.close_helper" | translate }} </span>
      </div>
    </div>

  </div>
</div>
