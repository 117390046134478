import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { Client, Orders } from '../../../core/interfaces/orders';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { debounceTime, distinctUntilChanged, fromEvent, startWith, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ModelService } from '../../../core/services/model/model.service';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.css',
})
export class FilterComponent implements OnChanges {
  @ViewChild('requireActionCheckbox') requireActionCheckbox!: ElementRef;
  @ViewChild('searchBox') searchBox!: ElementRef;
  @Output() filterChanged = new EventEmitter<any>();
  @Input() ladata: any = [];
  submenuOpen: boolean = false;
  closeTimeout: any;
  isSearchTriggeredByEnter = false;

  dataSource = new MatTableDataSource<Orders>();
  orders: any[] = [];
  preventFocus: boolean = false;
  brandSearchTerm: string = '';
  modelSearchTerm: string = '';
  agentSearchTerm: string = '';
  displayTextBrand: string = '';
  displayTextModel: string = '';
  optionsVisibleBrand: boolean = false;
  optionsVisibleModel: boolean = false;
  searchParamsEst: any = {};
  searchParams: any = {};

  showDropdown = false;
  showClear = false;

  searchTerm = '';//;

  stageSearchTerm = '';
  clientSearchTerm = '';
  brokerSearchTerm = '';
  supplierSearchTerm = '';
  vendorSearchTerm = '';
  statusSearchTerm = '';
  searchText = '';

  accountType: string | null = null;


  searchTerms: Subject<string> = new Subject<string>();

  autoFetch: any;

  filterValuesEst: {
    sent_date: any | null;
    risk_approved_date: any | null;
    contract_signed_date: any | null;
    vehicle_delivered_date: any | null;
  } = { sent_date: [], risk_approved_date: [], contract_signed_date: [], vehicle_delivered_date: [] };


  filterValues: {
    search: string | null;
    client: Client | null;
    vendor: string | null;
    supplier: string | null;
    agent: string | null;
    broker: string | null;
    brokerReference: string | null;
    vehicle_brand: string | null;
    vehicle_model: string | null;
    stage: string | null;
    require_action: boolean | null;
    status: string | null;
  } = {
      search: '',
      client: null,
      vendor: null,
      supplier: null,
      agent: null,
      broker: null,
      brokerReference: null,
      vehicle_brand: null,
      vehicle_model: null,
      stage: null,
      require_action: null,
      status: null,
    };

  constructor(
    private clientService: ClientsService,
    private localStorageService: LocalStorageService,
    private modelService: ModelService,
    private route: ActivatedRoute
  ) {
    this.accountType = this.localStorageService.getItem('account_type');
  }

  ngAfterViewInit() {
    // Detectar cambios en el input con debounce y evitar duplicados
    this.searchTerms
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => {
        if (!this.isSearchTriggeredByEnter) {
          this.search();
        }
        this.isSearchTriggeredByEnter = false; // Resetear bandera después de debounce
      });

    // Detectar eventos de teclado para manejar Enter
    fromEvent<KeyboardEvent>(this.searchBox.nativeElement, 'keyup')
      .subscribe((event: KeyboardEvent) => {
        const term = (event.target as HTMLInputElement).value;
        this.searchTerms.next(term);
        if (event.key === 'Enter') {
          this.isSearchTriggeredByEnter = true; // Marcar como búsqueda por Enter
          this.search(); // Ejecutar búsqueda inmediata
        }
      });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['filter']) {
        let filter = params['filter'];
        if (filter) {
          this.localStorageService.setItem('searchParams', filter);
        }
      }
    });
  }
  /***********/
  ngOnChanges(changes: SimpleChanges) {
    if (changes['ladata'] && changes['ladata'].currentValue) {
      if (this.ladata.flag) {
        this.fetchAllData();
        this.checkFilters();
      }
      // else {
      //   this.fetchAllData();
      // }

    }
  }

  checkFilters(): void {
    /*  if (this.localStorageService.getItem('searchParamsEst') && this.ladata.typeOrders == '') {
       this.searchParamsEst = JSON.parse(this.localStorageService.getItem('searchParamsEst') || '{}');
       if (Object.keys(this.searchParamsEst).length !== 0) {
         this.preCargarFiltrosEst();
       }
     } else */
    if (this.localStorageService.getItem('searchParams')) {
      this.searchParams = JSON.parse(this.localStorageService.getItem('searchParams') || '{}');
      if (Object.keys(this.searchParams).length !== 0) {
        this.preCargarFiltros();
      }
    }
  }

  private fetchAllData() {
    Promise.all([
      this.fillFilter('', 'agentData', 'agentAccounts'),
      this.fillFilter('', 'brokerData', 'brokerAccounts'),
      this.fillFilter('', 'vendorData', 'vendorAccounts'),
      this.fillFilter('', 'supplierData', 'supplierAccounts'),
      this.fillFilter('', 'brands', 'filteredBrands'),
      this.fillFilter('', 'stages', 'filteredStages'),

    ]).then(() => { });
  }

  /***********/

  supplierAccounts: any[] = [];
  brokerAccounts: any[] = [];
  vendorAccounts: any[] = [];
  agentAccounts: any[] = [];
  filteredStages: any[] = [];
  filteredBrands: any[] = [];
  filteredStatus: any[] = [
    { key: 'new', name: 'Nuevo', selected: false },
    { key: 'used', name: 'Usado', selected: false }
  ];

  fillFilter(term: string, dataKey: string, targetKey: string): void {
    if (this.ladata && this.ladata[dataKey]) {
      (this as any)[targetKey] = this.ladata[dataKey].filter((item: any) =>
        item.name.toLowerCase().includes(term.toLowerCase())
      )
        .map((stages: any) => ({
          ...stages,
          selected: false
        }));
    }
  }

  setAllSelectedFalse(targetKey: string): void {
    (this as any)[targetKey] = (this as any)[targetKey].map((item: any) => ({
      ...item,
      selected: false,
    }));
  }

  /******  ******/
  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.closeTimeout = setTimeout(() => {
      this.showDropdown = false;
    }, 4000); //  1 seconds
  }

  startCloseTimeout(): void {
    this.closeTimeout = setTimeout(() => {
      this.showDropdown = false;
    }, 500); //  1 seconds
  }

  cancelCloseTimeout(): void {
    this.showDropdown = true;
    clearTimeout(this.closeTimeout);
  }

  /****** Type ******/

  optionsVisibleSupplier: boolean = false;
  optionsVisibleBroker: boolean = false;
  optionsVisibleVendor: boolean = false;
  optionsVisibleClient: boolean = false;
  optionsVisibleStage: boolean = false;
  optionsVisibleAgent: boolean = false;
  optionsVisibleStatus: boolean = false;

  displayTextSupplier: string = '';
  displayTextBroker: string = '';
  displayTextVendor: string = '';
  displayTextClient: string = '';
  displayTextStage: string = '';
  displayTextAgent: string = '';
  displayTextStatus: string = '';

  clearableModel = false;
  clearableBrand = false;
  clearableStage = false;
  clearableClient = false;
  clearableBroker = false;
  clearableAgent = false;
  clearableSupplier = false;
  clearableVendor = false;
  clearableStatus = false;

  /****** ******/
 /*  preCargarFiltrosEst(): void {

    if (this.searchParamsEst['sent_date[between][]']) {
      const formattedString = this.searchParamsEst['sent_date[between][]'].replace(/'/g, '"');
      this.filterValuesEst.sent_date = JSON.parse(formattedString);
    }
    if (this.searchParamsEst['risk_approved_date[between][]']) {
      const formattedString = this.searchParamsEst['risk_approved_date[between][]'].replace(/'/g, '"');
      this.filterValuesEst.risk_approved_date = JSON.parse(formattedString);
    }
    if (this.searchParamsEst['contract_signed_date[between][]']) {
      const formattedString = this.searchParamsEst['contract_signed_date[between][]'].replace(/'/g, '"');
      this.filterValuesEst.contract_signed_date = JSON.parse(formattedString);
    }
    if (this.searchParamsEst['vehicle_delivered_date[between][]']) {
      const formattedString = this.searchParamsEst['vehicle_delivered_date[between][]'].replace(/'/g, '"');
      this.filterValuesEst.vehicle_delivered_date = JSON.parse(formattedString);
    }

    this.searchEst()
  } */


  preCargarFiltros(): void {
    if (this.searchParams['search']) {
      this.filterValues.search = this.searchParams['search'];
      this.searchText = this.searchParams['search'];
    }
    if (this.searchParams['stage[in]']) {
      this.precargarSelectedAttributeStage(this.searchParams['stage[in]']);
      this.filterValues.stage = this.searchParams['stage[in]'];
      this.updateDisplayTextStage();
      // this.displayTextStage = this.ladata.stages.find(
      //   (stage: any) => stage.key === this.filterValues.stage
      // ).name;
      this.stageSearchTerm = this.displayTextStage;
      this.clearableStage = true;
      this.optionsVisibleStage = false;
      this.selectedFilters.add('stage');

    }

    if (this.searchParams['supplier_reference_id[in]']) {
      this.precargarSelectedAttributeSupplier(this.searchParams['supplier_reference_id[in]']);
      this.filterValues.supplier = this.searchParams['supplier_reference_id[in]'];
      this.updateDisplayTextSupplier();
      this.supplierSearchTerm = this.displayTextSupplier;
      this.clearableSupplier = true;
      this.selectedFilters.add('supplier');

    }

    if (this.searchParams['vendor_reference_id[eq]']) {
      this.filterValues.vendor = this.searchParams['vendor_reference_id[eq]'];
      this.displayTextVendor = this.ladata.vendorData.find(
        (vendor: any) => vendor.reference === this.filterValues.vendor
      ).name;
      this.clearableVendor = true;
      this.vendorSearchTerm = this.displayTextVendor;
      this.selectedFilters.add('vendor');
    }

    if (this.searchParams['broker_reference_id[eq]']) {
      this.filterValues.broker = this.searchParams['broker_reference_id[eq]'];
      this.displayTextBroker = this.ladata.brokerData.find(
        (broker: any) => broker.reference === this.filterValues.broker
      ).name;
      this.clearableBroker = true;
      this.brokerSearchTerm = this.displayTextBroker;
      this.selectedFilters.add('broker');
    }
    // if (this.searchParams['vehicle_brand[like]']) {
    //   this.filterValues.vehicle_brand = this.searchParams['vehicle_brand[like]'];
    //   this.displayTextBrand = this.searchParams['vehicle_brand[like]'];
    //   this.clearableBrand = true;
    //   this.brandSearchTerm = this.displayTextBrand;
    //   this.selectedFilters.add('brand');
    // }

    if (this.searchParams['vehicle_brand_id[in]']) {
      this.precargarSelectedAttributeBrand(this.searchParams['vehicle_brand_id[in]']);
      this.filterValues.vehicle_brand = this.searchParams['vehicle_brand_id[in]'];
      this.updateDisplayTextBrand();
      this.brandSearchTerm = this.displayTextBrand;
      this.clearableBrand = true;
      this.selectedFilters.add('brand');

    }

    if (this.searchParams['vehicle_model[like]']) {
      this.filterValues.vehicle_model = this.searchParams['vehicle_model[like]'];
      this.displayTextModel = this.searchParams['vehicle_model[like]'];
      this.clearableModel = true;
      this.modelSearchTerm = this.displayTextModel;
      this.selectedFilters.add('model');
    }
    if (this.searchParams['client_name[like]']) {
      this.filterValues.client = this.searchParams['client_name[like]'];
      this.displayTextClient = this.searchParams['client_name[like]'];
      this.clearableClient = true;
      this.clientSearchTerm = this.displayTextClient;
      this.selectedFilters.add('client');
    }
    if (this.searchParams['broker_agent_id[eq]']) {
      this.filterValues.agent = this.searchParams['broker_agent_id[eq]'];
      this.displayTextAgent = this.ladata.agentData.find(
        (agent: any) => agent.reference === this.filterValues.agent
      ).name;
      this.clearableAgent = true;
      this.agentSearchTerm = this.displayTextAgent;
      this.selectedFilters.add('agent');
    }

    if (this.searchParams['vehicle_status[eq]']) {
      this.filterValues.status = this.searchParams['vehicle_status[eq]'];
      this.displayTextStatus = this.filteredStatus.find(
        (status: any) => status.key === this.filterValues.status
      ).name;
      this.clearableStatus = true;
      this.statusSearchTerm = this.displayTextStatus;
      this.optionsVisibleStatus = false;
      this.selectedFilters.add('status');
    }
    this.updateButtonLabel();
    this.search()
  }

 /*  searchEst(): void {
    this.searchParams = {};
    if (this.filterValuesEst.sent_date.length > 0) {
      this.searchParams[
        'sent_date[between][]'
      ] = this.filterValuesEst.sent_date;
    }
    if (this.filterValuesEst.risk_approved_date.length > 0) {
      this.searchParams[
        'risk_approved_date[between][]'
      ] = this.filterValuesEst.risk_approved_date;
    }
    if (this.filterValuesEst.contract_signed_date.length > 0) {
      this.searchParams[
        'contract_signed_date[between][]'
      ] = this.filterValuesEst.contract_signed_date;
    }
    if (this.filterValuesEst.vehicle_delivered_date.length > 0) {
      this.searchParams[
        'vehicle_delivered_date[between][]'
      ] = this.filterValuesEst.vehicle_delivered_date;
    }
    this.localStorageService.removeItem('searchParamsEst');

    this.emitEvent();

  } */
  /****** ******/

  onFocus(filterType: string): void {
    this.hideOptions();
    switch (filterType) {
      case 'status':
        this.optionsVisibleStatus = true;
        break;
      case 'supplier':
        this.optionsVisibleSupplier = true;
        break;
      case 'broker':
        this.optionsVisibleBroker = true;
        break;
      case 'vendor':
        this.optionsVisibleVendor = true;
        break;
      case 'client':
        this.optionsVisibleClient = true;
        break;
      case 'stage':
        this.optionsVisibleStage = true;
        break;
      case 'agent':
        this.optionsVisibleAgent = true;
        break;
      case 'brand':
        this.optionsVisibleBrand = true;
        break;
      case 'model':
        this.optionsVisibleModel = true;
        break;
    }
  }


  /********  INPUT  **********/

  keyup(event: KeyboardEvent) {
    this.filterValues.search = (event.target as HTMLInputElement).value;
    this.searchTerms.next(this.filterValues.search);
  }

  /********  FILTER **********/

  /******* STAGES *******/

  onSelectStage(stage: any, inputElement: HTMLInputElement): void {
    this.addSelectedAttributeStage(stage.key);
    // this.displayTextStage = stage.name;
    this.updateDisplayTextStage();
    this.stageSearchTerm = this.displayTextStage;
    this.optionsVisibleStage = false;
    this.filterValues.stage = stage.key;
    this.clearableStage = true;
    this.selectedFilters.add('stage');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  addSelectedAttributeStage(keysToSelect: string) {
    this.filteredStages = this.filteredStages.map(stage => ({
      ...stage,
      selected: stage.key === keysToSelect ? !stage.selected : stage.selected, // Toggle si las claves coinciden
    }));
  }

  precargarSelectedAttributeStage(keysToSelect: string) {
    const keysArray = keysToSelect.split(',');
    this.filteredStages = this.filteredStages.map(stage => ({
      ...stage,
      // Marca como seleccionado si la clave está en el array y alterna su estado
      selected: keysArray.includes(stage.key) ? !stage.selected : stage.selected,
    }));
  }

  updateDisplayTextStage() {
    this.displayTextStage = this.filteredStages
      .filter((stage) => stage.selected)
      .map((stage) => stage.name)
      .join(', ');
  }

  // setAllSelectedFalseStage() {
  //   this.filteredStages = this.filteredStages.map(stage => ({
  //     ...stage,
  //     selected: false
  //   }));
  // }


  /******** STATUS *******/

  onSelectStatus(status: any, inputElement: HTMLInputElement): void {
    this.displayTextStatus = status.name;
    this.statusSearchTerm = status.name; // Limpiamos el término de búsqueda
    this.optionsVisibleStatus = false;
    this.filterValues.status = status.key;
    this.clearableStatus = true;
    this.selectedFilters.add('status');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  /******* SUPPLIER *******/

  onSelectSupplier(supplier: any, inputElement: HTMLInputElement) {
    // this.displayTextSupplier = supplier.name;
    this.addSelectedAttributeSupplier(supplier.reference);
    this.updateDisplayTextSupplier();
    this.supplierSearchTerm = this.displayTextSupplier;
    this.optionsVisibleSupplier = false;
    this.clearableSupplier = true;
    this.filterValues.supplier = supplier.reference;
    this.selectedFilters.add('supplier');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  addSelectedAttributeSupplier(keysToSelect: string) {
    this.supplierAccounts = this.supplierAccounts.map(supplier => ({
      ...supplier,
      selected: supplier.reference === keysToSelect ? !supplier.selected : supplier.selected, // Toggle si las claves coinciden
    }));
  }
  precargarSelectedAttributeSupplier(keysToSelect: string) {
    const keysArray = keysToSelect.split(',');
    this.supplierAccounts = this.supplierAccounts.map(supplier => ({
      ...supplier,
      // Marca como seleccionado si la clave está en el array y alterna su estado
      selected: keysArray.includes(supplier.reference) ? !supplier.selected : supplier.selected,
    }));
  }
  updateDisplayTextSupplier() {
    this.displayTextSupplier = this.supplierAccounts
      .filter((stage) => stage.selected)
      .map((stage) => stage.name)
      .join(', ');
  }
  /******* VENDOR *******/

  onSelectVendor(vendor: any, inputElement: HTMLInputElement) {
    this.displayTextVendor = vendor.name;
    this.vendorSearchTerm = vendor.name;
    this.optionsVisibleVendor = false;
    this.clearableVendor = true;
    this.filterValues.vendor = vendor.reference;
    this.selectedFilters.add('vendor');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  /******* AGENT *******/

  onSelectAgent(agent: any, inputElement: HTMLInputElement) {
    this.displayTextAgent = agent.name + ' ' + agent.lastname;
    this.agentSearchTerm = agent.name + ' ' + agent.lastname;
    this.optionsVisibleAgent = false;
    this.clearableAgent = true;
    this.filterValues.agent = agent.reference;
    this.selectedFilters.add('agent');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  /******* BROKER *******/

  onSelectBroker(broker: any, inputElement: HTMLInputElement) {
    this.displayTextBroker = broker.name;
    this.brokerSearchTerm = broker.name;
    this.optionsVisibleBroker = false;
    this.clearableBroker = true;
    this.filterValues.broker = broker.reference;
    this.selectedFilters.add('broker');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  /******** BRAND FILTER **********/

  onSelectBrand(brand: any, brandInput: HTMLInputElement) {
    // this.displayTextBrand = brand.name;
    this.addSelectedAttributeBrand(brand.reference);
    this.updateDisplayTextBrand();
    this.brandSearchTerm = this.displayTextBrand;
    this.optionsVisibleBrand = false;
    this.filterValues.vehicle_brand = brand.name;
    this.clearableBrand = true;
    this.updateButtonLabel();
    this.searchModelForBrand(brand.reference);
    this.search();
    brandInput.blur();
    this.clearSelectedOption('model')
  }

  addSelectedAttributeBrand(keysToSelect: string) {
    this.filteredBrands = this.filteredBrands.map(brand => ({
      ...brand,
      selected: brand.reference === keysToSelect ? !brand.selected : brand.selected, // Toggle si las claves coinciden
    }));
  }
  precargarSelectedAttributeBrand(keysToSelect: string) {
    const keysArray = keysToSelect.split(',');
    this.filteredBrands = this.filteredBrands.map(brand => ({
      ...brand,
      // Marca como seleccionado si la clave está en el array y alterna su estado
      selected: keysArray.includes(brand.reference) ? !brand.selected : brand.selected,
    }));
  }

  updateDisplayTextBrand() {
    this.displayTextBrand = this.filteredBrands
      .filter((brand) => brand.selected)
      .map((brand) => brand.name)
      .join(', ');
  }

  /********  MODAL FILTER **********/
  async searchModelForBrand(value: any) {
    if (!value) return;
    var brand_reference: any = '';
    if (typeof value === 'string') {
      brand_reference = value;
    } else {
      brand_reference = value.target.value;
    }

    try {
      const data = await this.modelService.getDataByBrand(brand_reference).toPromise();
      this.ladata.models = data.data;
      this.searchModels('');

    } catch (error) {
      console.error('There was an error!', error);
    }
  }

  filteredModels: any[] = [];

  searchModels(term: string): void {
    if (this.ladata && this.ladata.models) {
      this.filteredModels = this.ladata.models.filter((model: any) =>
        model.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectModel(model: any, modelInput: HTMLInputElement) {
    this.displayTextModel = model.name;
    this.modelSearchTerm = model.name;
    this.optionsVisibleModel = false;
    this.filterValues.vehicle_model = model.name;
    this.clearableModel = true;
    this.updateButtonLabel();
    this.search();
    modelInput.blur();
  }


  /*******Filters label*******/

  hideOptions(): void {
    this.optionsVisibleSupplier = false;
    this.optionsVisibleBroker = false;
    this.optionsVisibleVendor = false;
    this.optionsVisibleClient = false;
    this.optionsVisibleBrand = false;
    this.optionsVisibleModel = false;
    this.optionsVisibleStage = false;
    this.optionsVisibleAgent = false;
    this.optionsVisibleStatus = false;
  }

  selectedFilters: Set<string> = new Set();

  updateButtonLabel() {
    const filterCount = this.selectedFilters.size;
    this.showClear = filterCount > 0;
  }

  get filterButtonLabel() {
    const filterCount = this.selectedFilters.size;
    return filterCount > 0 ? `Filtrar (${filterCount})` : 'Filtrar';
  }

  clearSelectedOption(filter: string): void {
    switch (filter) {
      case 'status':
        this.displayTextStatus = '';
        this.statusSearchTerm = '';
        this.selectedFilters.delete('status');
        this.filterValues.status = null;
        break;
      case 'require_action':
        this.selectedFilters.delete('require_action');
        this.filterValues.require_action = null;
        this.requireActionCheckbox.nativeElement.checked = false;
        break;
      case 'agent':
        this.displayTextAgent = '';
        this.agentSearchTerm = '';
        this.clearableAgent = false;
        this.selectedFilters.delete('agent');
        this.filterValues.agent = null;
        break;
      case 'stage':
        this.displayTextStage = '';
        this.stageSearchTerm = '';
        this.clearableStage = false;
        this.selectedFilters.delete('stage');
        this.setAllSelectedFalse('filteredStages');
        break;
      case 'client':
        this.displayTextClient = '';
        this.clientSearchTerm = '';
        this.clearableClient = false;
        this.selectedFilters.delete('client');
        this.filterValues.client = null;
        break;
      case 'broker':
        this.displayTextBroker = '';
        this.brokerSearchTerm = '';
        this.clearableBroker = false;
        this.selectedFilters.delete('broker');
        this.filterValues.broker = null;

        break;
      case 'supplier':
        this.displayTextSupplier = '';
        this.supplierSearchTerm = '';
        this.clearableSupplier = false;
        this.selectedFilters.delete('supplier');
        this.setAllSelectedFalse('supplierAccounts');
        break;
      case 'vendor':
        this.displayTextVendor = '';
        this.vendorSearchTerm = '';
        this.clearableVendor = false;
        this.selectedFilters.delete('vendor');
        this.filterValues.vendor = null;
        break;
      case 'brand':
        this.displayTextBrand = '';
        this.brandSearchTerm = '';
        this.clearableBrand = false;
        this.selectedFilters.delete('brand');
        this.filterValues.vehicle_brand = null;
        this.setAllSelectedFalse('filteredBrands');
        break;
      case 'model':
        this.displayTextModel = '';
        this.modelSearchTerm = '';
        this.clearableModel = false;
        this.selectedFilters.delete('model');
        this.filterValues.vehicle_model = null;
        break;
    }
    this.updateButtonLabel();
    this.search();

    if (this.selectedFilters.size === 0) {
      this.showClear = false;
    }
  }

  onCheckboxChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.filterValues.require_action = true;
      this.selectedFilters.add('require_action');
      this.search();
    } else {
      this.clearSelectedOption('require_action');
    }
  }

  clearFilter(event: Event): void {
    event.stopPropagation();
    this.filterValues = {
      search: '',
      client: null,
      vendor: null,
      broker: null,
      agent: null,
      supplier: null,
      vehicle_brand: null,
      vehicle_model: null,
      brokerReference: null,
      stage: null,
      require_action: null,
      status: null,
    };
    this.setAllSelectedFalse('filteredStages');
    this.setAllSelectedFalse('filteredBrands');
    this.setAllSelectedFalse('filteredModels');
    this.setAllSelectedFalse('filteredStatus');
    this.setAllSelectedFalse('supplierAccounts');
    this.setAllSelectedFalse('brokerAccounts');
    this.setAllSelectedFalse('vendorAccounts');
    this.setAllSelectedFalse('agentAccounts');

    this.searchText = '';

    this.stageSearchTerm = '';
    this.clientSearchTerm = '';
    this.supplierSearchTerm = '';
    this.brokerSearchTerm = '';
    this.vendorSearchTerm = '';
    this.brandSearchTerm = '';
    this.modelSearchTerm = '';
    this.stageSearchTerm = '';
    this.statusSearchTerm = '';

    this.displayTextStage = '';
    this.displayTextClient = '';
    this.displayTextBroker = '';
    this.displayTextSupplier = '';
    this.displayTextVendor = '';
    this.displayTextBrand = '';
    this.displayTextModel = '';
    this.displayTextAgent = '';
    this.displayTextStatus = '';

    this.clearableStage = false;
    this.clearableClient = false;
    this.clearableBroker = false;
    this.clearableAgent = false;
    this.clearableSupplier = false;
    this.clearableVendor = false;
    this.clearableBrand = false;
    this.clearableModel = false;
    this.clearableStatus = false;

    this.localStorageService.removeItem('searchParams');

    this.requireActionCheckbox.nativeElement.checked = false;

    this.showClear = false;
    this.selectedFilters.clear();
    this.searchParams = {};
    this.updateButtonLabel();
    this.hideOptions();
    this.emitEvent();

  }

  emitEvent(): void {
    this.filterChanged.emit(this.searchParams);
  }

  /************ SEARCH *************/

  search(): void {
    this.searchParams = {};
    if (this.filterValues.search) {
      this.searchParams['search'] = `${this.filterValues.search}`;
    }

    if (this.filterValues.require_action) {
      this.searchParams['require_action'] = this.filterValues.require_action;
    }

    if (this.filterValues.client) {
      this.searchParams[
        'client_name[like]'
      ] = `%${this.filterValues.client.name}%`;
    }

    if (this.filterValues.status) {
      this.searchParams[
        'vehicle_status[eq]'
      ] = `${this.filterValues.status}`;
    }

    if (this.filterValues.vendor) {
      this.searchParams[
        'vendor_reference_id[eq]'
      ] = `${this.filterValues.vendor}`;
    }

    // if (this.filterValues.supplier) {
    //   this.searchParams[
    //     'supplier_reference_id[eq]'
    //   ] = `${this.filterValues.supplier}`;
    // }

    if (this.supplierAccounts.some(supplier => supplier.selected)) {
      // this.searchParams['supplier[in]'] = `${this.filterValues.supplier}`;

      const selectedSuppliers = this.supplierAccounts
        .filter(supplier => supplier.selected) // Filtra los elementos seleccionados
        .map(supplier => supplier.reference) // Extrae las claves (keys)
      this.searchParams['supplier_reference_id[in]'] = selectedSuppliers.join(','); // Une las claves con comas
    }



    if (this.filterValues.brokerReference) {
      this.searchParams[
        'broker_reference_id[eq]'
      ] = `${this.filterValues.brokerReference}`;
    }

    if (this.filterValues.broker) {
      this.searchParams[
        'broker_reference_id[eq]'
      ] = `${this.filterValues.broker}`;
    }

    // if (this.filterValues.vehicle_brand) {
    //   this.searchParams[
    //     'vehicle_brand[like]'
    //   ] = `%${this.filterValues.vehicle_brand}%`;
    // }

    if (this.filteredBrands.some(brand => brand.selected)) {
      const selectedBrands = this.filteredBrands
        .filter(brand => brand.selected) // Filtra los elementos seleccionados
        .map(brand => brand.reference) // Extrae las claves (keys)
      this.searchParams['vehicle_brand_id[in]'] = selectedBrands.join(','); // Une las claves con comas
    }

    if (this.filterValues.vehicle_model) {
      this.searchParams[
        'vehicle_model[like]'
      ] = `%${this.filterValues.vehicle_model}%`;
    }
    if (this.filterValues.agent) {
      if (this.accountType == 'broker') {
        this.searchParams['broker_agent_id[eq]'] = `${this.filterValues.agent}`;
      }
      if (this.accountType == 'supplier') {
        this.searchParams[
          'supplier_agent_id[eq]'
        ] = `${this.filterValues.agent}`;
      }
    }

    if (this.filteredStages.some(stage => stage.selected)) {
      // this.searchParams['stage[in]'] = `${this.filterValues.stage}`;
      const selectedStages = this.filteredStages
        .filter(stage => stage.selected) // Filtra los elementos seleccionados
        .map(stage => stage.key) // Extrae las claves (keys)
      this.searchParams['stage[in]'] = selectedStages.join(','); // Une las claves con comas
    }


    this.emitEvent();
    let store_searchParams = this.searchParams;
    //store_searchParams['search'] = '';
    this.localStorageService.setItem('searchParams', JSON.stringify(store_searchParams).replace(/\%/g, ""))
    this.showClear = true;
    /* this.fetchData(); */
  }


}
