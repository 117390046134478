import { Component, Input, OnDestroy } from '@angular/core';
import { AuthService } from '../../../core/services/auth/auth.service';
import { RouterLink } from '@angular/router';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '../../../core/services/notification/notification.service';
import { UsersService } from '../../../core/services/users/users.service';
import { OrdersService } from '../../../core/services/orders/orders.service';
import { Subscription } from 'rxjs';

interface Notification {
  reference: string;
  type: string;
  title: string;
  content: string;
  url: string;
  expiration_date: string;
  read_status: boolean;
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink, CommonModule, TranslateModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent implements OnDestroy {
  @Input() collapsed = false; // Recibe el estado del `mainComponent`
  role: any = 'admin';
  environment = environment.production;
  autoFetch: any;
  showNotifications = false;
  notifications: Notification[] = [];
  unreadCount: number = 0;

  userData: any;
  userImageUrl: string | null = null;
  actionRequiredCount = 0;

  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private usersService: UsersService,
    private orderService: OrdersService
  ) {
    this.role = this.localStorageService.getItem('role');
  }
  private subscriptions = new Subscription();
  ngOnInit(): void {
    this.orderService.initializeActionRequiredAutoUpdate();
    this.fetchUserData();
    this.orderService.actionRequiredCount$.subscribe((count) => {
      this.actionRequiredCount = count;
    });

    this.getNumber();


    this.fetchAllData();
    this.autoFetch = setInterval(() => {
      this.fetchAllData();
    }, 120000);

    this.usersService.userImage$.subscribe((imageUrl) => {
      this.userImageUrl = imageUrl;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private fetchAllData() {
    Promise.all([this.fetchData()]).then(() => {
      this.unreadCount = this.notificationService.countUnreadNotifications(
        this.notifications
      );
    });
  }

  fetchData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.notificationService.getData().subscribe({
        next: (data) => {
          this.notifications = data.data;
          this.unreadCount = this.notificationService.countUnreadNotifications(
            this.notifications
          );
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  markAsRead(reference: string): void {
    this.notificationService.updateStatus(reference, { read: true }).subscribe(
      (response) => {},
      (error) => {}
    );
    this.notificationService.markAsRead(reference);
    this.unreadCount = this.notificationService.countUnreadNotifications(
      this.notifications
    );
  }

  isRead(reference: string): boolean {
    const aux = this.notifications.find((n) => n.reference === reference);
    if (aux?.read_status) {
      return true;
    }
    // Verifica si la notificación está en las cookies o si su read_status es 1
    return this.notificationService.isRead(reference);
  }

  toggleNotificationsMenu() {
    this.showNotifications = !this.showNotifications;
  }

  logout() {
    this.authService.logout();
  }

  fetchUserData(): void {
    this.usersService.getUserByReference().subscribe(
      (response) => {
        this.userData = response.data;
        this.userImageUrl = this.userData.img;
      },
      (error) => {
        console.error('Error loading user data:', error);
      }
    );
  }
  private linkElement?: HTMLLinkElement;
  changeTheme(theme: string) {
    this.linkElement = document.createElement('link');
    this.linkElement.rel = 'stylesheet';
    document.head.appendChild(this.linkElement);
    document.body.classList.remove(
      'theme-blue',
      'theme-brown',
      'theme-green',
      'theme-orange'
    );

    // Agrega la nueva clase de tema si existe
    if (theme) {
      document.body.classList.add(theme);
    }
  }
  count: number = 0;
  getNumber() {
    this.orderService.getActionRequiredCount().subscribe({
      next: (response) => {
        this.actionRequiredCount = response.data?.action_requireds;
      },
      error: (err) => {
        console.error('Error al obtener la cantidad de notas:', err);
      },
    });
  }
}
