
<div class="row d-flex justify-content-center">
  <div class="mb-3 col-2" *ngIf="accountType !== 'broker'">
    <div class="main-selector-container">
      <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleBroker }">
        <input class="text" #brokerInput type="text" (focus)="onFocus('broker')" (blur)="hideOptions()"
          [(ngModel)]="brokerSearchTerm" (input)="searchBroker(brokerSearchTerm)"
          placeholder="{{ 'forms.inputs.broker' | translate }}" [value]="displayTextBroker" />
        <span *ngIf="clearableBroker" class="clear-icon" (click)="
                                  clearSelectedOption('broker'); $event.stopPropagation()
                                "><i class="icon-cross2"></i></span>
        <span class="icon-chevron-down"></span>
      </div>
      <div class="options-container" *ngIf="optionsVisibleBroker" [ngClass]="{ visible: optionsVisibleBroker }">
        <div *ngFor="let broker of brokerAccounts" class="option-value" (mousedown)="
                                  onSelectBroker(broker,brokerInput)">
          <div>{{ broker.name }}</div>
        </div>
      </div>
    </div>


  </div>

  <div class="mb-3 col-2" *ngIf="accountType !== 'supplier'">
    <div class="main-selector-container">
      <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleSupplier }">
        <input class="text" #supplierInput type="text" (focus)="onFocus('supplier')" (blur)="hideOptions()"
          [(ngModel)]="supplierSearchTerm" (input)="searchSupplier(supplierSearchTerm)"
          placeholder="{{ 'forms.inputs.supplier' | translate }}" [value]="displayTextSupplier" />
        <span *ngIf="clearableSupplier" class="clear-icon" (click)="
                                  clearSelectedOption('supplier'); $event.stopPropagation()
                                "><i class="icon-cross2"></i></span>
        <span class="icon-chevron-down"></span>
      </div>
      <div class="options-container" *ngIf="optionsVisibleSupplier" [ngClass]="{ visible: optionsVisibleSupplier }">
        <div *ngFor="
                                  let supplier of supplierAccounts
                                " class="option-value" (mousedown)="onSelectSupplier(supplier, supplierInput)">
          <div>{{ supplier.name }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3 col-2" *ngIf="accountType !== 'vendor'">
    <div class="main-selector-container">
      <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleVendor }">
        <input class="text" #vendorInput type="text" (focus)="onFocus('vendor')" (blur)="hideOptions()"
          [(ngModel)]="vendorSearchTerm" (input)="searchVendor(vendorSearchTerm)"
          placeholder="{{ 'forms.inputs.vendor' | translate }}" [value]="displayTextVendor" />
        <span *ngIf="clearableVendor" class="clear-icon" (click)="
                                clearSelectedOption('vendor'); $event.stopPropagation()
                              "><i class="icon-cross2"></i></span>
        <span class="icon-chevron-down"></span>
      </div>
      <div class="options-container" *ngIf="optionsVisibleVendor" [ngClass]="{ visible: optionsVisibleVendor }">
        <div *ngFor="let vendor of vendorAccounts" class="option-value" (mousedown)="
                                onSelectVendor(vendor,vendorInput)">
          <div>{{ vendor.name }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3 col-2">
    <div class="main-selector-container">
      <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleBrand }">
        <input class="text" #brandInput type="text" (focus)="onFocus('brand')" (blur)="hideOptions()"
          [(ngModel)]="brandSearchTerm" placeholder="{{ 'forms.inputs.vehicle_brand' | translate }}"
          (input)="searchBrands(brandSearchTerm)" [value]="displayTextBrand" />
        <span *ngIf="clearableBrand" class="clear-icon" (click)="
                                clearSelectedOption('brand'); $event.stopPropagation()
                              "><i class="icon-cross2"></i></span>
        <span class="icon-chevron-down"></span>
      </div>
      <div class="options-container" *ngIf="optionsVisibleBrand" [ngClass]="{ visible: optionsVisibleBrand }">
        <div class="option-value" *ngFor="let brand of filteredBrands" (mousedown)="onSelectBrand(brand, brandInput)">
          <div>{{ brand.name }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3 col-2">
    <div class="main-selector-container">
      <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleStatus }">
        <input class="text" #statusInput type="text" (focus)="onFocus('status')" (blur)="hideOptions()"
          [(ngModel)]="statusSearchTerm" placeholder="{{ 'filter.status' | translate }}" [value]="displayTextStatus" />
        <span *ngIf="clearableStatus" class="clear-icon" (click)="
                              clearSelectedOption('status'); $event.stopPropagation()
                            "><i class="icon-cross2"></i></span>

        <span class="icon-chevron-down"></span>
      </div>
      <div class="options-container" *ngIf="optionsVisibleStatus" [ngClass]="{ visible: optionsVisibleStatus }">
        <div class="option-value" *ngFor="let status of filteredStatus"
          (mousedown)="onSelectStatus(status, statusInput)">
          <div>{{ "forms.inputs.status_select."+status.key | translate}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row d-flex justify-content-center">
  <div class="mb-3 col-2">
    <div class="main-selector-container">
      <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleStage }">
        <input class="text" #stageInput type="text" (focus)="onFocus('stage')" (blur)="hideOptions()"
          [(ngModel)]="stageSearchTerm" placeholder="{{ 'filter.stage' | translate }}" [value]="displayTextStage" />
        <span *ngIf="clearableStage" class="clear-icon" (click)="
                              clearSelectedOption('stage'); $event.stopPropagation()
                            "><i class="icon-cross2"></i></span>

        <span class="icon-chevron-down"></span>
      </div>
      <div class="options-container" *ngIf="optionsVisibleStage" [ngClass]="{ visible: optionsVisibleStage }">
        <div class="option-value" *ngFor="let stage of filteredStages"
          (mousedown)="onSelectStage(stage, stageInput)">
          <div>{{ "forms.inputs.status_select."+stage.key | translate}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3 col-2">
    <div class="main-selector-container">
      <div class="reports-filter">
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="startDate" placeholder="{{ 'reports.filter.start_date' | translate }}"
            (dateChange)="onDateChange($event, 'startDate')" id="startDateInput" readonly />
          <span *ngIf="clearableStartDate" class="clear-icon"
            (click)="clearDate('startDate'); $event.stopPropagation()">
            <i class="icon-cross2"></i>
          </span>

          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="mb-3 col-2">
    <div class="main-selector-container">
      <div class="reports-filter">
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="endDate" placeholder="{{ 'reports.filter.end_date' | translate }}"
            (dateChange)="onDateChange($event, 'endDate')" id="endDateInput" readonly>

          <span *ngIf="clearableEndDate" class="clear-icon" (click)="clearDate('endDate'); $event.stopPropagation()">
            <i class="icon-cross2"></i>
          </span>

          <mat-datepicker-toggle matSuffix [for]="endDate" style="border:0 !important"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
