import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VehiclesService {
  private apiUrl = environment.apiURL + 'vehicles'; // URL del API
  constructor(private http: HttpClient) { }

  // Método para obtener datos de la API
  getData(): Observable<any> {
    return this.http.get(this.apiUrl);
  }

  // Método para añadir datos a la API
  addData(item: any): Observable<any> {
    return this.http.post(this.apiUrl + '/new', item);
  }

  topModelBrand(supplier_reference: any,brand :any): Observable<any> {
    return this.http.get(this.apiUrl + '/top-models/' + supplier_reference + "/" + brand);
  }

  topModel(supplier_reference: any,model :any): Observable<any> {
    return this.http.get(this.apiUrl + '/top-vehicles/' + supplier_reference + "/" + model);
  }

}
