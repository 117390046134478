<header id="page-topbar" class="isvertical-topbar color-fondo"  [ngClass]="{ 'header-grande': collapsed}" >
  <div class="nb" [ngClass]="{dev:  !environment }" >
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="#" class="logo logo-light">
            <span class="logo-lg">
              <img src="assets/images/logo-abbr-clear.png" alt="" height="22" />
            </span>
            <span class="logo-sm">
              <img src="assets/images/logo-abbr-clear.png" alt="" height="22" />
            </span>
          </a>
        </div>

        <button type="button"
          class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn topnav-hamburger"></button>
      </div>
      <div *ngIf="0" class="theme-selector">
        <div class="color-box default" (click)="changeTheme('')" title="Tema Predeterminado"></div>
        <div class="color-box blue" (click)="changeTheme('theme-blue')" title="Tema Azul"></div>
        <div class="color-box brown" (click)="changeTheme('theme-brown')" title="Tema Marrón"></div>
        <div class="color-box green" (click)="changeTheme('theme-green')" title="Tema Verde"></div>
        <div class="color-box orange" (click)="changeTheme('theme-orange')" title="Tema Naranja"></div>
      </div>

      <h1 *ngIf="!environment">Demo</h1>
      <div class="d-flex">

        <div class="dropdown d-inline-block">
          <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
            id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

            <i class="icon-alarm"></i>
          </button>
          <span class="position-absolute icon-pill translate-middle badge rounded-pill" *ngIf="unreadCount > 0">
            {{unreadCount > 99 ? '99+' : unreadCount}}
            <span class="visually-hidden">unread notifications</span>
          </span>
          <div *ngIf=(notifications) class="dropdown-menu dropdown-menu-end pt-0 div-notif">
            <div class="p-3 border-bottom notif" *ngFor="let notification of notifications">
              <div [class.read]="isRead(notification.reference)">
                <a (click)="markAsRead(notification.reference)" href="{{notification.url}}">
                  <h6 class="mb-0 notif-title"> {{notification.title}}</h6>
                  <p class="mb-0 font-size-11 text-muted notif-content"> {{notification.content}}</p>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="menu-container">
          <a href="notes">
            <button type="button" class="btn header-item noti-icon">
              <i class=" icon-inbox"></i>
            </button>
            <span class="position-absolute icon-pill translate-middle badge rounded-pill"
              *ngIf="actionRequiredCount > 0" class="badge">{{ actionRequiredCount }}
            </span>
          </a>
        </div>
        <div class="dropdown d-inline-block">
          <a href="https://help.digitalbuyersmanager.com/" target="_blank">
            <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown">
              <i class="icon-question-circle"></i>
            </button>
          </a>
        </div>
        <div class="dropdown d-inline-block">
          <a *ngIf="role == 'Admin'" routerLink="/settings">
            <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown">
              <i class="icon-cog"></i>
            </button>
          </a>
        </div>
        <div class="dropdown d-inline-block">
          <button type="button" class="btn header-item user text-start d-flex align-items-center"
            id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <!--  <img class="rounded-circle header-profile-user" src="assets/images/user-empty.png" alt="Header Avatar" /> -->
            <img class="rounded-circle header-profile-user" [src]="userImageUrl || 'assets/images/user-empty.png'"
              alt="Header Avatar" />
          </button>
          <div class="dropdown-menu dropdown-menu-end pt-0">
            <div class="p-3 border-bottom">
              <h6 class="mb-0">DBM</h6>
              <p class="mb-0 font-size-11 text-muted">digitalbuyersmanager</p>
            </div>
            <div class="div-profile">
              <a href="/settings/profile">
                <i class="icon-user"></i>
                <h3 class="pl-3">{{"settings.profile.my-profile" | translate}} </h3>
              </a>
            </div>
            <div class="div-logout" (click)="logout()">
              <i class="mdi mdi-logout text-muted font-size-16 align-middle me-1 icon-exit"></i>
              <span class="align-middle"> {{ "logout" | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</header>
<!-- <div class="div-logout" (click)="logout()">
    <span><i class="icon-exit"></i>{{ "logout" | translate }}</span>
  </div> -->
