<div class="col-12 ">
  <div class="btn-izq mb-2">
    <button class="btn btn-hover" (click)="openAddOrderForm2()">{{ "buttons.add+" | translate }}<i
        class="icon-plus"></i></button>
    <!--  <button class="btn btn-hover" (click)="openAddOrderForm()">{{ "buttons.add+" | translate }}<i
        class="icon-plus"></i></button> -->
  </div>
  <div>
    <div class="tabla">
      <table mat-table class="full-width-table" matSort aria-label="Brands" multiTemplateDataRows
        [dataSource]="dataSource">
        <!-- (matSortChange)="onSortChange($event)"> -->
        <!-- broker_reference Column --> [ngClass]="getBadgeColorClient(row.client?.type)"
        <ng-container matColumnDef="broker_reference">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
            {{ "tables.columns.broker_reference" | translate }} <i *ngIf="sortColumn == 'broker_reference'"></i>
          </th>
          <td mat-cell *matCellDef="let row">{{ row?.broker_reference }}</td>
        </ng-container>

        <!-- Supplier Column -->
        <ng-container matColumnDef="supplier">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
            {{ "tables.columns.supplier" | translate }} <i *ngIf="sortColumn == 'supplier'"></i>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="column-list">
              <span *ngIf="row?.supplier?.name">
                {{ row?.supplier?.name }}</span>
              <div class="d-flex flex-column">

                <span *ngIf="row?.supplier_agent?.name" class="secondary-text">
                  <i class="icon-user"></i> {{ row?.supplier_agent?.name }}
                  {{ row?.supplier_agent?.lastname }}</span>
                <span *ngIf="row?.supplier_reference" class="secondary-text">
                  <i class="icon-barcode"></i> {{ row?.supplier_reference }}</span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- vendor Column -->
        <ng-container matColumnDef="vendor">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
            {{ "tables.columns.vendor" | translate }} <i *ngIf="sortColumn == 'vendor'"></i>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="column-list">
              <span *ngIf="row?.vendor?.name"> {{ row?.vendor?.name }}</span>

              <div class="d-flex flex-column">
                <span *ngIf="row?.broker_agent?.agent" class="secondary-text">
                  <i class="icon-user"></i> {{ row?.broker_agent?.name }}</span>
                <span *ngIf="row?.vendor_reference" class="secondary-text">
                  <i class="icon-barcode"></i> {{ row?.vendor_reference }}</span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- vehicle Column -->
        <ng-container matColumnDef="vehicle">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
            {{ "tables.columns.vehicle" | translate }} <i *ngIf="sortColumn == 'vehicle'"></i>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="column-list">
              <span *ngIf="row?.vehicle">   <span *ngIf="row?.vehicle_units">{{ row?.vehicle_units }}x</span> {{ row?.vehicle_brand }} {{ row?.vehicle_model }} </span>
              <span *ngIf="row?.vehicle">{{ row?.vehicle }}
                ({{row?.vehicle_model_year}}) </span>
              <span *ngIf="row?.vehicle_status" class="badge text-badge-list"
                [ngClass]="getBadgeColorVehicle(row.vehicle_status)">{{ "badge."
                + row?.vehicle_status | translate }}</span>

              <div class="d-flex flex-column">
                <span *ngIf="row?.colour" class="secondary-text">
                  <i class="icon-brush2"></i> {{ row?.colour }}</span>
                <span class="area-table"></span>
                <span *ngIf="row?.engine" class="secondary-text">
                  {{ row?.engine }}</span>
                <span *ngIf="row?.vin" class="secondary-text">
                  {{ row?.vin }}</span>
                <span *ngIf="row?.duration" class="secondary-text">
                  {{ row?.duration }}
                  {{ "tables.months" | translate }}</span>
                <span *ngIf="row?.total_kms" class="secondary-text">
                  {{ row?.total_kms }} kms</span>
                <span *ngIf="row?.license_plate" class="secondary-text">
                  {{ row?.license_plate }}</span>
                <span *ngIf="row?.monthly_fee_total" class="secondary-text">
                  <i class="icon-coin-euro"> </i>
                  {{ row?.monthly_fee_total }} Є/{{ "tables.month" | translate}}</span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- updated_at Column -->
        <ng-container matColumnDef="updated_at">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
            {{ "tables.columns.updated_at" | translate }} <i *ngIf="sortColumn == 'updated_at'"></i>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="d-flex flex-column">
              <div class="column-list">
                <span *ngIf="row?.updated_at">
                  {{ row?.updated_at | date: 'yyyy-MM-dd' }}
                </span>
                <span *ngIf="row?.updated_at" class="secondary-text">
                  <i class="icon-user"></i>
                  {{ row?.updated_by }}
                </span>
              </div>
            </div>

          </td>
        </ng-container>

        <!-- stage Column -->
        <ng-container matColumnDef="stage">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
            {{ "tables.columns.stage" | translate }} <i *ngIf="sortColumn == 'stage'"></i>
          </th>
          <td mat-cell *matCellDef="let row">
            <span class="badge text-badge-list" [ngClass]="getBadgeColor(row.stage,row.require_action,row.priority)">{{
              "badge." + row?.stage | translate
              }}</span>
          </td>
        </ng-container>

        <!-- actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
            {{ "tables.columns.actions" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <i class="icon-pencil text-success icon-actions"  (click)="getOrder(row.reference, row.stage)"  aria-hidden="true"></i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{'tr-selected': expandedRow?.reference === row.reference}"></tr>
        <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->

      </table>
    </div>
  </div>
</div>
