import { Routes } from '@angular/router';
import { TitleResolver } from '../resolver/metatitles/metatitles.resolver';
import { ReportsViewComponent } from '../../pages/reports/reports-view/reports-view.component';
import { ReportsComponent } from '../../pages/reports/reports.component';

export const reportsRoutes: Routes = [
  {
         path: 'reports',
         component: ReportsComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.reports' },
       },
       {
         path: 'reports/:type',
         component: ReportsViewComponent,
         resolve: { title: TitleResolver },
         data: { title: 'titles.reports', type: 'contracts' },
       },
];
