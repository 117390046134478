import {
  Component,
  inject,
  HostListener,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, Location } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { Sort } from '@angular/material/sort';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.css',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    AsyncPipe,
    RouterLink,
    TranslateModule,
  ],
})
export class NavigationComponent {
  private breakpointObserver = inject(BreakpointObserver);
  submenuOrders = false;
  submenuInvoices = false;
  account_name: any;
  submenuOpen: boolean = false;
  submenuOpenConf: boolean = false;
  submenuPosition = { top: '0px', left: '0px' };
  closeTimeout: any;
  closeTimeoutConf: any;
  name: any = 'Negocio';
  type: any = 'broker';
  role: any = 'admin';

  menuItemsBroker = [
    {
      label: 'menu.orders',
      icon: 'icon-clipboard-check',
      key: 't-orders',
      children: [
        {
          label: 'menu.sqls',
          icon: 'icon-plus',
          route: '/orders/sqls',
          key: 't-sqls',
        },
        {
          label: 'menu.drafts',
          icon: 'icon-plus',
          route: '/orders/drafts',
          key: 't-drafts',
        },
        {
          label: 'menu.offers',
          icon: 'icon-document',
          route: '/orders/offers',
          key: 't-offers',
        },
        {
          label: 'menu.rejected',
          icon: 'icon-outbox',
          route: '/orders/rejected',
          key: 't-rejected',
        },
        {
          label: 'menu.denied',
          icon: 'icon-thumbs-down',
          route: '/orders/denied',
          key: 't-denied',
        },
        {
          label: 'menu.contracts',
          icon: 'icon-register',
          route: '/orders/contracts',
          key: 't-contracts',
        },
        {
          label: 'menu.after-sales',
          icon: 'icon-history',
          route: '/orders/after-sales',
          key: 't-after-sales',
        },
        {
          label: 'menu.delivered',
          icon: 'icon-car',
          route: '/orders/delivered',
          key: 't-delivered',
        },
        {
          label: 'menu.cancellation_requested',
          icon: 'icon-bubble-alert',
          route: '/orders/cancellation-requested',
          key: 't-cancellation-requested',
        },
        { label: 'menu.all', icon: 'icon-box', route: '/orders', key: 't-all' },
      ],
    },
    {
      label: 'menu.clients',
      icon: 'icon-user',
      key: 't-clients',
      route: '/clients',
    },

    {
      label: 'menu.invoices',
      icon: 'icon-clipboard-check',
      key: 't-invoices',
      role: ['Supervisor', 'Admin'],
      children: [
        {
          label: 'menu.drafts',
          icon: 'icon-plus',
          route: '/invoices/drafts',
          key: 't-drafts',
        },
        {
          label: 'menu.pending',
          icon: 'icon-register',
          route: '/invoices/pending',
          key: 't-pending',
        },
        {
          label: 'menu.paid',
          icon: 'icon-history',
          route: '/invoices/paid',
          key: 't-paid',
        },
        {
          label: 'menu.cancelled',
          icon: 'icon-cross-circle',
          route: '/invoices/cancelled',
          key: 't-cancelled',
        },
        {
          label: 'menu.all',
          icon: 'icon-box',
          route: '/invoices',
          key: 't-all',
        },
      ],
    },
    {
      label: 'menu.cancelled',
      icon: 'icon-cross-circle',
      key: 't-cancelled',
      route: '/orders/cancelled',
    },
    {
      label: 'menu.reports',
      icon: 'icon-chart-bars',
      key: 't-reports',
      role: ['Supervisor', 'Admin'],
      route: '/reports',
    },
  ];

  menuItemsSupplier = [
    {
      label: 'menu.orders',
      icon: 'icon-clipboard-check',
      key: 't-orders',
      route: '',
      children: [
        {
          label: 'menu.offers',
          icon: 'icon-document',
          route: '/orders/offers',
          key: 't-offers',
        },
        {
          label: 'menu.rejected',
          icon: 'icon-outbox',
          route: '/orders/rejected',
          key: 't-rejected',
        },
        {
          label: 'menu.denied',
          icon: 'icon-thumbs-down',
          route: '/orders/denied',
          key: 't-denied',
        },
        {
          label: 'menu.contracts',
          icon: 'icon-register',
          route: '/orders/contracts',
          key: 't-contracts',
        },
        {
          label: 'menu.after-sales',
          icon: 'icon-history',
          route: '/orders/after-sales',
          key: 't-after-sales',
        },
        {
          label: 'menu.delivered',
          icon: 'icon-car',
          route: '/orders/delivered',
          key: 't-delivered',
        },
        {
          label: 'menu.cancellation_requested',
          icon: 'icon-bubble-alert',
          route: '/orders/cancellation-requested',
          key: 't-cancellation-requested',
        },
        { label: 'menu.all', icon: 'icon-box', route: '/orders', key: 't-all' },
      ],
    },
    {
      label: 'menu.invoices',
      icon: 'icon-clipboard-check',
      key: 't-invoices',
      route: '',
      children: [
        {
          label: 'menu.pending',
          icon: 'icon-register',
          route: '/invoices/pending',
          key: 't-pending',
        },
        {
          label: 'menu.paid',
          icon: 'icon-history',
          route: '/invoices/paid',
          key: 't-paid',
        },
        {
          label: 'menu.cancelled',
          icon: 'icon-cross-circle',
          route: '/invoices/cancelled',
          key: 't-cancelled',
        },
        {
          label: 'menu.all',
          icon: 'icon-box',
          route: '/invoices',
          key: 't-all',
        },
      ],
    },
    {
      label: 'menu.cancelled',
      icon: 'icon-cross-circle',
      key: 't-cancelled',
      route: '/orders/cancelled',
    },
  ];

  menuItems: any;

  constructor(
    private localStorageService: LocalStorageService,
    private location: Location
  ) {
    this.name = this.localStorageService.getItem('name');
    this.type = this.localStorageService.getItem('account_type');
    this.account_name = this.localStorageService.getItem('account_name');
    this.role = this.localStorageService.getItem('role');
    this.menuItems =
      this.type === 'broker' ? this.menuItemsBroker : this.menuItemsSupplier;
    const nuevaURL = this.location.path();
    if (nuevaURL.includes('orders/contracts')) {
      this.submenuOrders = !this.submenuOrders;
    }
  }

  toggleSubmenuOrders(): void {
    this.toggleNavigationFalse();
    this.submenuOrders = !this.submenuOrders;
    this.closeSubmenuInvoices();
  }

  closeSubmenuOrders(): void {
    this.submenuOrders = false;
  }

  toggleSubmenuInvoices(): void {
    this.toggleNavigationFalse();
    this.submenuInvoices = !this.submenuInvoices;
    this.closeSubmenuOrders();
  }

  closeSubmenuInvoices(): void {
    this.submenuInvoices = false;
  }

  toggleSubmenu(event: MouseEvent): void {

    // this.toggleNavigationFalse();
    this.submenuOpen = !this.submenuOpen;
    if (this.submenuOpen) {
      const target = (event.target as HTMLElement).closest(
        '.has-submenu'
      ) as HTMLElement;
      const rect = target.getBoundingClientRect();
      this.submenuPosition = {
        top: `${rect.top}px`,
        left: `${rect.right}px`,
      };
    }
    event.stopPropagation();
  }

  startCloseTimeout(): void {
    this.closeTimeout = setTimeout(() => {
      this.submenuOpen = false;
    }, 3000); // 3 seconds
  }

  cancelCloseTimeout(): void {
    this.submenuOpen = true;
    clearTimeout(this.closeTimeout);
  }

  toggleSubmenuConf(event: MouseEvent): void {
    // this.toggleNavigationFalse();
    this.submenuOpenConf = !this.submenuOpenConf;
    if (this.submenuOpenConf) {
      const target = (event.target as HTMLElement).closest(
        '.has-submenu'
      ) as HTMLElement;
      const rect = target.getBoundingClientRect();
      this.submenuPosition = {
        top: `${rect.top}px`,
        left: `${rect.right}px`,
      };
    }
    event.stopPropagation();
  }
  startCloseTimeoutConf(): void {
    this.closeTimeoutConf = setTimeout(() => {
      this.submenuOpenConf = false;
    }, 3000); // 3 seconds
  }

  cancelCloseTimeoutConf(): void {
    this.submenuOpenConf = true;
    clearTimeout(this.closeTimeoutConf);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (this.submenuOpen) {
      this.submenuOpen = false;
    }
  }
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  @Input() collapsed = false; // Recibe el estado del `mainComponent`
  @Output() toggle = new EventEmitter<boolean>(); // Emite un evento al hacer clic

  toggleNavigation(boolean: any): void {
    if (boolean) {
      this.closeSubmenuInvoices();
      this.closeSubmenuOrders();
    }
    this.collapsed = boolean; // Cambia el estado del `mainComponent`
    this.toggle.emit(boolean); // Notifica al componente principal
  }
  toggleNavigationFalse(): void {
    this.toggle.emit(false); // Notifica al componente principal
  }
}
